import objectUtils from '../utils/object-utils'

export default class SubscriberLookupInfo {
  constructor (options = {}) {
    this.model = 'SubscriberLookupInfo'
    this.id = objectUtils.get(options, 'id', null)
    this.name = objectUtils.get(options, 'name', '')
    this.customer_id = objectUtils.get(options, 'customer_id', null)
    this.group_id = objectUtils.get(options, 'group_id', null)
    this.user_id = objectUtils.get(options, 'user_id', null)
    this.cust_id = objectUtils.get(options, 'cust_id', null)
    this.cust_type = objectUtils.get(options, 'cust_type', null)
    this.cust_category = objectUtils.get(options, 'cust_category', null)
    this.pickup_location_id = objectUtils.get(options, 'pickup_location_id', null)
    this.pickup_location_status = objectUtils.get(options, 'pickup_location_status', null)
    this.loc_id = objectUtils.get(options, 'loc_id', null)
    this.org_name = objectUtils.get(options, 'org_name', null)
    this.org_nickname = objectUtils.get(options, 'org_nickname', null)
    this.org_url_alias = objectUtils.get(options, 'org_url_alias', null)
    this.group_url_alias = objectUtils.get(options, 'group_url_alias', null)
  }
}
