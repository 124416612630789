import React from 'react'
import objectUtils from '../../../utils/object-utils'
import './index.css'

export default function CommercialStats (props) {
  const stats = props.stats || {}
  // console.log('[DEVING] CommercialStats:', stats)

  const moduleTitle = 'Commercial Totals'
  const modulePrimaryTextColor = '#000000'

  return (
    <div className='commercial-stats'>
      <p className='module-title' style={{ color: modulePrimaryTextColor }}>
        {moduleTitle}
      </p>
      <div>
        total: {displayTotalWeight({ stats })}
      </div>
      <div>
        businesses: {stats.commercial_count || 0}
      </div>
    </div>
  )
}

function displayTotalWeight (options) {
  const stats = options.stats || {}
  const totalWeight = objectUtils.get(stats, 'total_weight', 0)

  return (
    totalWeight.toLocaleString()
  )
}
