import Tooltip from '@material-ui/core/Tooltip'
import 'react-circular-progressbar/dist/styles.css'
import ProgressCircle from './ProgressCircle'
import './index.scss'
import iconCalc from '../../../assets/calc.svg'
import iconDustbin from '../../../assets/dustbin.svg'
import iconInfoFilled from '../../../assets/icon-info-filled.svg'

export default function OrganizationTotalResults (props) {
  const organization = props.organization || {}
  const branding = props.branding || {}
  const groups = props.groups || []
  const remoteGroups = props.remoteGroups // the groups from a remote org source
  const activeGroup = props.activeGroup || {}
  const groupType = props.groupType

  // Module title
  const moduleTitle = props.title

  // Component field naming
  const orgNameForDisplay = organization.nickname || organization.name
  const orgTotalBinsLabel = branding.bins_label || 'Bins'
  const orgTotalGroupsLabel = branding.locations_label || 'Locations'
  const orgTotalWeightSubtext = `Total weight for ${orgNameForDisplay}`
  // const orgTotalBinsTooltip = `Total number of ${orgTotalBinsLabel}`
  // const orgTotalGroupsTooltip = `Total number of ${orgTotalGroupsLabel}`
  const orgCircleGraphText = `${activeGroup.name} contribution`

  // Configurable options
  const showLocationsCount = (branding.show_locations_count !== false)
  const showBinsCount = (branding.show_bins_count !== false)

  // Module font color settings
  const modulePrimaryTextColor = props.modulePrimaryTextColor
  const moduleSecondaryTextColor = props.moduleSecondaryTextColor

  // Determine org total value
  let orgTotalWeight = 0
  // If remote groups are being used, show the total from the group that matches the active org
  if (remoteGroups) {
    const matchingGroup = remoteGroups.find(group => group.url_alias === organization.url_alias)
    if (matchingGroup && matchingGroup.stats_total && matchingGroup.stats_total.total_in_lbs) orgTotalWeight = matchingGroup.stats_total.total_in_lbs
  // Otherwise, calculate the total by summing all groups
  } else {
    orgTotalWeight = groups.reduce((total, group) => {
      const itemTotal = (group.stats_total && group.stats_total.total_in_lbs) ? group.stats_total.total_in_lbs : 0
      return total + itemTotal // sum of all group totals
    }, 0)
  }

  // Calculate active group values
  const activeGroupTotalWeight = (activeGroup.stats_total) ? activeGroup.stats_total.total_in_lbs : 0
  const groupPercentage = (orgTotalWeight > 0)
    ? ((activeGroupTotalWeight / orgTotalWeight) * 100).toFixed(2)
    : 0

  // Filter out groups with active pickup locations
  const activeGroups = (groupType)
    ? groups.filter(group => group.group_type === groupType).filter(group => group?.pickup_location?.location_code === 'ACTIVE') // filter out specified group type
    : groups.filter(group => group?.pickup_location?.location_code === 'ACTIVE')

  // Total active group count
  const orgTotalActiveGroups = activeGroups.length

  // Calculate total bins of active groups
  const orgTotalBins = activeGroups.reduce((total, group) => {
    return total + group.total_bins // sum of all group bins
  }, 0)

  return (
    <div className='org-total-results'>
      <p id='total-results' className='module-title' style={{ color: modulePrimaryTextColor }}>
        {moduleTitle}
      </p>
      <div className='total-results-split-container'>
        <div className='total-results-left'>
          <p id='total-results' className='module-title' style={{ color: modulePrimaryTextColor }}>
            {moduleTitle}
          </p>
          <div className='total-weight-container'>
            <p className='subtext' style={{ color: moduleSecondaryTextColor }}>{orgTotalWeightSubtext}</p>
            <p>
              <span className='total-weight' style={{ color: modulePrimaryTextColor }}>
                {Math.round(orgTotalWeight).toLocaleString()}&nbsp;
              </span>
              <span className='total-weight-lbs' style={{ color: moduleSecondaryTextColor }}>lbs.</span>
            </p>
          </div>
          <ul className='attribute-list'>
            <AttributeListItem
              dataItem={orgTotalBins}
              label={orgTotalBinsLabel}
              icon={iconDustbin}
              textColor={moduleSecondaryTextColor}
              isVisible={showBinsCount}
            />
            <AttributeListItem
              dataItem={orgTotalActiveGroups}
              label={orgTotalGroupsLabel}
              icon={iconCalc}
              textColor={moduleSecondaryTextColor}
              isVisible={showLocationsCount}
            />
          </ul>
        </div>
        <div className='total-results-right'>
          <p className='subtext' style={{ marginBottom: '1px', color: moduleSecondaryTextColor }}>{orgCircleGraphText}</p>
          <ProgressCircle percentage={groupPercentage} />
        </div>
      </div>
    </div>
  )
}

const AttributeListItem = ({ dataItem, label, icon, textColor, isVisible, tooltip }) => isVisible && (
  <li>
    <img className='icon' src={icon} />
    <div className='total-results-text' style={{ color: textColor }}>
      {dataItem} {label}
    </div>
    {tooltip && (
      <Tooltip title={tooltip} placement='right-start' disableFocusListener>
        <img src={iconInfoFilled} className='tooltip-logo' />
      </Tooltip>
    )}
  </li>
)
