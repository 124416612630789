import React from 'react'
// import DataClient from '../../../services/dataClient'
import './index.scss'

export default function CommunityPageTitle (props) {
  // const branding = props.branding || {}
  const communityOrg = props.communityOrg || {}

  // Load branding colors
  // const brandingColors = DataClient.generateBrandingColors(branding)
  // const pageLabelTextColor = brandingColors.pageLabelTextColor
  const pageLabelTextColor = '#0a509e'

  return (
    <div className='page-title'>
      <div className='title-box'>
        <div className='title' style={{ color: pageLabelTextColor }}>{communityOrg.name}</div>
        <hr />
        <div className='sub-title' style={{ color: pageLabelTextColor }}>Compost Results</div>
      </div>
    </div>
  )
}
