import React from 'react'
import objectUtils from '../../../utils/object-utils'
import DataClient from '../../../services/dataClient'
import MetricDisplay from '../../app/MetricDisplay'
import ShareControls from '../../app/ShareControls'
import './index.scss'
import './grid.scss'

export default function CommunityPageContent (props) {
  const branding = props.branding || {}
  const activeUri = props.activeUri
  // const communityOrg = props.communityOrg || {}
  const communityStats = props.communityStats || {}

  // Extract info from stats
  const subscriberCount = communityStats.group_count
  const cityAlias = communityStats.parent_org_alias

  // Set metrics to show
  const metricsToShow = ['trees-planted', 'miles-undriven', 'co2-saved']

  // Load branding colors
  const brandingColors = DataClient.generateBrandingColors(branding)
  const modulePrimaryTextColor = brandingColors.modulePrimaryTextColor
  const moduleSecondaryTextColor = brandingColors.moduleSecondaryTextColor
  // const pageLabelTextColor = brandingColors.pageLabelTextColor

  return (
    <div className='page-content-container'>
      <div className='grid-container'>
        {/* Floating Buttons ----------------------------------------------- */}
        <div className='btn-container'>
          {activeUri && (
            <ShareControls activeUri={activeUri} />
          )}
        </div>

        {/* Total Weight Metrics ------------------------------------------- */}
        <div className='card metrics weight'>
          <span className='generated-total-weight-nbr' style={{ color: modulePrimaryTextColor }}>
            {displayCommunityTotalWeight({ communityStats })}&nbsp;
            <sup id='lbs' style={{ color: moduleSecondaryTextColor }}>lbs.</sup>
          </span>
          <div className='card-subtext' style={{ color: moduleSecondaryTextColor }}>
            Diverted from landfill
          </div>
        </div>
        {metricsToShow.map((metric, index) => {
          return (
            <div className={`card metrics metric-card-${index}`} key={`metric_card_${index}`}>
              <MetricDisplay
                metricToShow={metric}
                timeframe='total'
                activeGroup={communityStats}
                modulePrimaryTextColor={modulePrimaryTextColor}
                moduleSecondaryTextColor={moduleSecondaryTextColor}
              />
            </div>
          )
        })}

        {/* Module Stats ----------------------------------------------- */}
        <div className='card stats'>
          <div>city: {cityAlias}</div>
        </div>

        {/* Module Stats ---------------------------------------------- */}
        <div className='card stats'>
          <div>subscribers: {subscriberCount}</div>
        </div>
      </div>
    </div>
  )
}

function displayCommunityTotalWeight (options) {
  const communityStats = options.communityStats || {}
  const totalWeight = objectUtils.get(communityStats, 'stats_total.total_in_lbs', 0)

  return (
    Math.round(totalWeight).toLocaleString()
  )
}
