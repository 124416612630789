import objectUtils from '../utils/object-utils'

export default class ContainerType {
  constructor (options = {}) {
    this.model = 'ContainerType'

    this.id = objectUtils.get(options, 'id', null)
    this.code = objectUtils.get(options, 'code', null)
    this.label = objectUtils.get(options, 'label', null)
    this.description = objectUtils.get(options, 'description', null)

    this.weight_in_lbs = objectUtils.get(options, 'weight_in_lbs', null)
    this.details = objectUtils.get(options, 'details', { color: null })
    this.category = objectUtils.get(options, 'category', null)

    this.created_at = objectUtils.get(options, 'created_at', null)
    this.updated_at = objectUtils.get(options, 'updated_at', null)
  }
}
