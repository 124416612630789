import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import AppHeader from '../../components/app/AppHeader'
import CommunityPageTitle from '../../components/community/CommunityPageTitle'
import CommunityPageContent from '../../components/community/CommunityPageContent'
import DataClient from '../../services/dataClient'
import './index.scss'

class CityPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeUri: '',
      branding: {},
      communityAlias: '',
      communityOrg: {},
      communityStats: {}
    }
  }

  async componentDidMount () {
    const communityAlias = this.props.data.communityAlias
    const communityOrg = this.props.data.communityOrg
    const branding = this.props.data.branding

    // Redirect to 404 page, if group does not exist
    // const is404 = !activeGroup
    // if (is404) this.props.history.push('/404')

    // Load city level stats
    const communityStats = await DataClient.fetchCommunityLevelStats(communityAlias)
    // console.log('[DEVING] communityStats =>', communityStats)

    // Build active page URI (for shares)
    const activeUri = `https://community-total.moonshotcompost.com/${communityAlias}`

    this.setState({
      activeUri,
      branding,
      communityAlias,
      communityOrg,
      communityStats
    })
  }

  render () {
    const {
      activeUri, branding, communityAlias, communityOrg, communityStats
    } = this.state

    const TooltipCss = withStyles({
      '@global': {
        '.MuiTooltip-popper': {
          marginTop: '8px',
          maxWidth: '180px'
        },
        '.MuiTooltip-tooltip': {
          margin: '0',
          padding: '16px 16px 18px 16px',
          backgroundColor: 'white',
          color: '#98B4CA',
          borderRadius: '0',
          boxShadow: '0px 2px 24px rgba(31, 36, 76, 0.25)',
          fontFamily: 'Lato',
          fontSize: '16px'
        }
      }
    })(() => null)

    return (
      <Grid container className='community-page' justify='center' spacing={0}>
        <Helmet>
          <title>{`${communityOrg.name} Compost Results`}</title>
          <meta name='description' content='Food waste is a valuable resource that we’re transforming into healthy soil.' />
        </Helmet>
        <TooltipCss />
        <Grid item xs={12}>
          <AppHeader
            branding={branding}
            organization='montrose'
          />
        </Grid>
        <Grid item xs={12}>
          <CommunityPageTitle
            branding={branding}
            communityAlias={communityAlias}
            communityOrg={communityOrg}
          />
        </Grid>
        <Grid item xs={12}>
          <CommunityPageContent
            branding={branding}
            activeUri={activeUri}
            communityAlias={communityAlias}
            communityOrg={communityOrg}
            communityStats={communityStats}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(CityPage)
