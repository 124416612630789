import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import CloseIcon from '@material-ui/icons/Close'
import WeightSubmissionForm from '../WeightSubmissionForm'
import MetricDisplay from '../../app/MetricDisplay'
import ShareControls from '../../app/ShareControls'
import OrganizationTotalResults from '../OrganizationTotalResults'
import OrganizationAssociations from '../OrganizationAssociations'
import GroupResultsChart from '../GroupResultsChart'
import './index.scss'
import './grid.scss'
import '../../modal/modal.scss'

export default function GroupPageContent (props) {
  const activeUri = props.activeUri
  const activeGroup = props.activeGroup || {}
  const subscriberTotal = props.subscriberTotal // NOTE: Use this total for residential (will be null if commercial)
  const organization = props.organization || {}
  const orgAssocs = props.orgAssocs
  const groups = props.groups || []
  const remoteGroups = props.remoteGroups // the groups from a remote org source

  // Branding options
  const branding = props.branding || {} // the full branding object
  const showWeeklyStats = (branding.show_weekly_stats !== false)
  const metricsToShow = props.metricsToShow || []
  const modulePrimaryTextColor = props.modulePrimaryTextColor
  const moduleSecondaryTextColor = props.moduleSecondaryTextColor
  const pageLabelTextColor = props.pageLabelTextColor
  const groupTypeToShow = props.groupTypeToShow
  const groupResultsTitle = props.groupResultsTitle
  const maxGroupsToShow = props.maxGroupsToShow
  const totalResultsTitle = props.totalResultsTitle
  const showOrgTotalResults = (branding.show_total_results_chart !== false)

  // Org Associations
  const showOrgAssociations = orgAssocs && orgAssocs.items.length > 0

  // Boolean for submit weight functionality
  const enableSubmitWeight = (process.env.REACT_APP_ENABLE_WEIGHT_SUBMISSION === 'yes' && activeGroup.cust_type === 'RD')

  // Build timeframe dropdown menu
  const [timeframe, setTimeframe] = useState('total')
  const [dropdown, handleDropdown] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setShowModal(true)
    setOpen(true)
  }

  const handleClose = () => {
    setShowModal(false)
    setOpen(false)
  }

  const timeframeChoices = [
    {
      timeframe: 'total',
      selected: true,
      title: 'Total'
    },
    {
      timeframe: 'monthly',
      selected: false,
      title: 'This Month'
    }
  ]
  if (showWeeklyStats) {
    timeframeChoices.push({
      timeframe: 'weekly',
      selected: false,
      title: 'This Week'
    })
  }

  function updateTimeframe (newVal) {
    setTimeframe(newVal)
    handleDropdown(!dropdown)
  }

  const timeframeListItems = timeframeChoices.map(choice =>
    <li className='dd-item' key={choice.timeframe} style={{ color: modulePrimaryTextColor }} onClick={() => updateTimeframe(choice.timeframe)}>
      {choice.title}
    </li>
  )

  return (
    <div className='page-content-container'>
      <div className='grid-container'>

        {/** *****************************************************************/}
        {/** ** Timeframe Selector and Submit Weight and Share Controls ******/}
        {/** *****************************************************************/}
        <div className='btn-container'>
          <div className='space-x-2'>
            {/** ************ timeframe dropdown **************/}
            <div className='dd-wrapper'>
              <button
                className='floating-btns dropdown weight-dd'
                onClick={() => handleDropdown(!dropdown)}
                style={dropdown ? { borderRadius: '8px 8px 0px 0px' } : { borderRadius: '8px' }}
              >
                <span className='floating-btn-label'>
                  <i className='total-weight-icon-weight' />
                  <div className='floating-btn-text-label'>
                    <ActiveTimeframeText timeframe={timeframe} />
                  </div>
                  <i className='total-weight-icon-small-down-arrow' />
                </span>
              </button>
              {dropdown && <div className='dd-list'>{timeframeListItems}</div>}
            </div>
            {/** ************ submit weight button **************/}
            {enableSubmitWeight && (
              <button className='floating-btns submit-weight' onClick={handleOpen}>
                <span className='floating-btn-label'>
                  <i className='submit-weight-icon-arrow' />
                  <p className='floating-btn-text-label' style={{ marginRight: '0' }}>Submit Weight</p>
                </span>
              </button>
            )}
          </div>
          {/** *************** submit weight form in modal ****************/}
          {showModal && (
            <Modal open={open} onClose={handleClose}>
              <div className='modal-container modal-container-group-weight-submit-form'>
                <span className='modal-close' onClick={() => handleClose()}>
                  <CloseIcon />
                </span>
                <WeightSubmissionForm
                  isShowing={open}
                  organization={organization}
                  activeGroup={activeGroup}
                  pageLabelTextColor={pageLabelTextColor}
                  handleClose={handleClose}
                />
              </div>
            </Modal>
          )}
          {/** *************** share controls ****************/}
          {activeUri && (
            <ShareControls activeUri={activeUri} />
          )}
        </div>

        {/** *****************************************************************/}
        {/** ** Metric Card Display ******************************************/}
        {/** *****************************************************************/}
        <div className='card metrics weight'>
          <span className='generated-total-weight-nbr' style={{ color: modulePrimaryTextColor }}>
            {generateActiveTotalWeight({ activeGroup, timeframe, subscriberTotal })}&nbsp;
            <sup id='lbs' style={{ color: moduleSecondaryTextColor }}>lbs.</sup>
          </span>
          <div className='card-subtext' style={{ color: moduleSecondaryTextColor }}>
            Diverted from landfill
          </div>
        </div>
        {metricsToShow.map((metric, index) => {
          return (
            <div className={`card metrics metric-card-${index}`} key={`metric_card_${index}`}>
              <MetricDisplay
                metricToShow={metric}
                timeframe={timeframe}
                activeGroup={activeGroup}
                subscriberTotal={subscriberTotal}
                modulePrimaryTextColor={modulePrimaryTextColor}
                moduleSecondaryTextColor={moduleSecondaryTextColor}
              />
            </div>
          )
        })}

        {/** *****************************************************************/}
        {/** ** Org Total Results ********************************************/}
        {/** *****************************************************************/}
        {showOrgTotalResults && (
          <div className='module-card module-full'>
            <OrganizationTotalResults
              title={totalResultsTitle}
              groups={groups}
              remoteGroups={remoteGroups}
              activeGroup={activeGroup}
              organization={organization}
              branding={branding}
              modulePrimaryTextColor={modulePrimaryTextColor}
              moduleSecondaryTextColor={moduleSecondaryTextColor}
            />
          </div>
        )}

        {/** *****************************************************************/}
        {/** ** Org Associations *********************************************/}
        {/** *****************************************************************/}
        {showOrgAssociations && (
          <div className='module-card module-full'>
            <OrganizationAssociations
              organization={organization}
              orgAssocs={orgAssocs}
              branding={branding}
              modulePrimaryTextColor={modulePrimaryTextColor}
              moduleSecondaryTextColor={moduleSecondaryTextColor}
            />
          </div>
        )}

        {/** *****************************************************************/}
        {/** ** Group Results Chart ******************************************/}
        {/** *****************************************************************/}
        <div className='module-card module-full'>
          <GroupResultsChart
            title={groupResultsTitle}
            organization={organization}
            groups={remoteGroups || groups}
            remoteGroups={remoteGroups}
            activeGroup={activeGroup}
            groupType={groupTypeToShow}
            modulePrimaryTextColor={modulePrimaryTextColor}
            moduleSecondaryTextColor={moduleSecondaryTextColor}
            pageLabelTextColor={pageLabelTextColor}
            maxGroupsToShow={maxGroupsToShow}
          />
        </div>
      </div>
    </div>
  )
}

function ActiveTimeframeText (props) {
  const timeframe = props.timeframe

  let text = ''
  switch (timeframe) {
    case 'total': text = 'Total'; break
    case 'monthly': text = 'This Month'; break
    case 'weekly': text = 'This Week'; break
  }
  return (
    <div className='timeframe-text'>{text}</div>
  )
}

function generateActiveTotalWeight (options) {
  const activeGroup = options.activeGroup || {}
  const timeframe = options.timeframe
  const subscriberTotal = options.subscriberTotal // a "subscriber" is a residential customer

  // Calculate total weights
  const groupTotalWeight = (activeGroup.stats_total) ? activeGroup.stats_total.total_in_lbs : 0
  const groupMonthlyWeight = (activeGroup.stats_monthly) ? activeGroup.stats_monthly.total_in_lbs : 0
  const groupWeeklyWeight = (activeGroup.stats_weekly) ? activeGroup.stats_weekly.total_in_lbs : 0

  let totalWeight = 0
  switch (timeframe) {
    case 'total': totalWeight = subscriberTotal || groupTotalWeight; break
    case 'monthly': totalWeight = groupMonthlyWeight; break
    case 'weekly': totalWeight = groupWeeklyWeight; break
  }
  totalWeight = (totalWeight) ? Math.round(totalWeight) : 0

  return (
    totalWeight.toLocaleString()
  )
}
