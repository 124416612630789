// Constants used for calculations
const co2EmittedPerGallon = 0.008887439298 // cell B9  122.62 / 13797
const co2SavedPerTree = 0.06 // for 10 years
const milesDriverPerGallon = 22.3
const ghgEmissionsWithLandfill = 0.4975
const ghgEmissionsComposted = -0.1155850279
// const eqForCars = -4.7100119607565

const calculations = {
  calculateCo2Saved,
  calculateMilesUndriven,
  calculateTreesPlanted
}

function calculateCo2Saved (weightInTons) {
  let result = 0

  // console.log('cell b3: ', weightInTons)
  const totalLandfill = ghgEmissionsWithLandfill * weightInTons // cell B15
  // console.log('cell b15: ', weightInTons)
  const totalCompost = ghgEmissionsComposted * weightInTons // cell B17
  // console.log('cell b17: ', weightInTons)
  result = (totalCompost - totalLandfill) * -2205 // cell B19
  // console.log('cell b19:', result)

  return result
}

function calculateMilesUndriven (weightInTons) {
  let result = 0

  const totalLandfill = ghgEmissionsWithLandfill * weightInTons // cell B15
  const totalCompost = ghgEmissionsComposted * weightInTons // cell B17
  const changeGhgEmissions = (totalCompost - totalLandfill) * -1 // cell B18
  const gasUnburned = changeGhgEmissions / co2EmittedPerGallon // cell B23

  result = gasUnburned * milesDriverPerGallon // cell B24

  return result
}

function calculateTreesPlanted (weightInTons) {
  let result = 0

  // console.log('cell b3: ', weightInTons)
  // b17 - b15 (b17 = ghgEmissionsComposted * weightInTons, b15 = ghgEmissionsWithLandfill * weightInTons)
  const totalCompost = ghgEmissionsComposted * weightInTons // cell B17
  // console.log('cell b17: ', totalCompost)
  const totalLandfill = ghgEmissionsWithLandfill * weightInTons // cell B15
  // console.log('cell b15: ', totalLandfill)
  const changeGhgEmissions = (totalCompost - totalLandfill) * -1 // cell B18
  // console.log('cell b18: ', changeGhgEmissions)
  const co2OneYear = co2SavedPerTree / 10 // cell b26
  // console.log('cell b26: ', co2OneYear)

  result = changeGhgEmissions / co2OneYear // cell b27
  // console.log('cell b27: ', result)

  return result
}

export default calculations
