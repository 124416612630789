import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from 'react-router-dom'
import Page404 from '../pages/404Page'
import SystemDown from '../pages/SystemDownPage'
import CustomPage from '../pages/CustomPage'
import CityPage from '../pages/CityPage'
import CommunityPage from '../pages/CommunityPage'
import GroupPage from '../pages/GroupPage'
import CustomerLookupPage from '../pages/CustomerLookupPage'
import CompostGuide from '../components/app/CompostGuide'
import PoweredByMoonshot from '../components/app/PoweredByMoonshot'
import './index.scss'

const isBackendDown = process.env.REACT_APP_ENABLE_SYSTEM_DOWN === 'yes'

export default function AppRouter (props) {
  const pageType = props.data.pageType
  const defaultRoute = `/${props.data.defaultGroup}`

  // Determine if compost guide should be shown
  const showCompostGuide = (window.location.pathname === '/submitweight')
    ? false
    : props.data.showCompostGuide

  return (
    <Router>
      <div>
        <Switch>
          {/* Backend system is down */}
          {isBackendDown && (
            <Route>
              <SystemDown />
            </Route>
          )}

          {/* Custom Pages */}
          {pageType === 'custom' && (
            <Route exact path='/custom/:orgAlias'>
              <CustomPage data={props.data} />
            </Route>
          )}
          {pageType === 'custom' && (
            <Route exact path='/:orgAlias'>
              <CustomPage data={props.data} />
            </Route>
          )}

          {/* City Pages */}
          {pageType === 'city' && (
            <Route exact path='/city-total/:cityAlias'>
              <CityPage data={props.data} />
            </Route>
          )}
          {pageType === 'city' && (
            <Route exact path='/:cityAlias'>
              <CityPage data={props.data} />
            </Route>
          )}

          {/* Community Pages */}
          {pageType === 'community' && (
            <Route exact path='/community-total/:communityAlias'>
              <CommunityPage data={props.data} />
            </Route>
          )}
          {pageType === 'community' && (
            <Route exact path='/:communityAlias'>
              <CommunityPage data={props.data} />
            </Route>
          )}

          {/* Group Pages */}
          {pageType === 'group' && (
            <Route exact path='/:orgId/:groupId'>
              <GroupPage data={props.data} />
            </Route>
          )}
          {pageType === 'group' && (
            <Route exact path='/:groupId'>
              <GroupPage data={props.data} />
            </Route>
          )}
          {pageType === 'group' && (
            <Route exact path='/'>
              <Redirect to={defaultRoute} />
            </Route>
          )}

          {/* Submit Weight Page */}
          <Route exact path='/submitweight'>
            <CustomerLookupPage data={props.data} />
          </Route>

          {/* Explicit 404 page request */}
          <Route exact path='/404'>
            <Page404 />
          </Route>

          {/* Serve 404 page on no matches */}
          <Route>
            <Page404 />
          </Route>
        </Switch>

        {/* Compost Guide */}
        {(() => {
          if (pageType === 'group' && showCompostGuide !== false) return <CompostGuide />
        })()}

        {/* Footer Area */}
        <div className='footer-area'>
          {pageType !== '404' && !isBackendDown && (
            <PoweredByMoonshot />
          )}
        </div>
      </div>
    </Router>
  )
}
