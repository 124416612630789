import React, { Component } from 'react'
import './index.scss'

export default class CompostGuide extends Component {
  render () {
    return (
      <div>
        <div className='compost-guide'>
          <h1>Compost Guide</h1>
          <div className='compost-col'>
            <h3 className='check-icon' style={{ color: '#43f1c2' }}>Great For Compost !</h3>
            <ul>
              <li>Fruits</li>
              <li>Vegetables</li>
              <li>Beans</li>
              <li>Nuts</li>
              <li>Coffee Grounds</li>
              <li>Coffee Filters</li>
              <li>Bread</li>
            </ul>
            <ul>
              <li>Grains</li>
              <li>Pasta</li>
              <li>Crackers</li>
              <li>Egg Shells</li>
              <li>Meat</li>
              <li>Poultry</li>
              <li>Seafood</li>
            </ul>
          </div>
          <div className='compost-col'>
            <h3 className='x-icon' style={{ color: '#ff6f88' }}>Do not Compost !</h3>
            <ul>
              <li>Glass</li>
              <li>Metals</li>
              <li>Plastics</li>
              <li>Fats or Oils</li>
              <li>Liquids</li>
            </ul>
            <ul>
              <li>Pet Waste</li>
              <li>Feces</li>
              <li>Litter</li>
              <li>Plastic Cups</li>
              <li>Plastic Plates</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
