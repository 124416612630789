import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import AppHeader from '../../components/app/AppHeader'
import GroupPageTitle from '../../components/group/GroupPageTitle'
import GroupPageContent from '../../components/group/GroupPageContent'
import DataClient from '../../services/dataClient'
import './index.scss'
import QrCode from '../../components/app/QrCode'

class GroupPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeUri: '',
      organization: {},
      orgAssocs: null,
      branding: {},
      groups: [],
      remoteGroups: [],
      activeGroup: {},
      groupTypeToShow: null,
      groupResultsTitle: null,
      bgImagePath: '',
      bgImageFromTop: 0,
      is404: false,
      modulePrimaryTextColor: '#0a509e',
      moduleSecondaryTextColor: '#98b4ca',
      pageLabelTextColor: '#FFFFFF',
      maxGroupsToShow: 10,
      metricsToShow: [],
      subscriberTotal: null
    }
  }

  async componentDidMount () {
    const orgAlias = this.props.data.orgAlias
    const organization = this.props.data.organization
    const branding = this.props.data.branding
    const groups = this.props.data.groups
    const remoteGroups = this.props.data.remoteGroups

    // Load active group and branding settings
    const groupId = this.props.match.params.groupId
    const activeGroup = groups.find(item => item.url_alias === groupId)
    const bgImageInfo = DataClient.generateBgImageInfo(orgAlias, branding, activeGroup)
    const bgImagePath = bgImageInfo.bgImagePath
    const bgImageFromTop = bgImageInfo.bgImageFromTop
    const groupTypeToShow = branding.group_type_to_show
    const groupResultsTitle = DataClient.generateGroupResultsTitle(branding)
    const maxGroupsToShow = DataClient.generateMaxGroupsToShow(branding)
    const totalResultsTitle = DataClient.generateTotalResultsTitle(branding)
    const metricsToShow = DataClient.generateMetricsToShow(branding)
    const showQrCode = activeGroup?.settings?.show_qr_code || false
    const qrCodeUrl = activeGroup?.settings?.qr_code_url || null

    // Redirect to 404 page, if group does not exist
    const is404 = !activeGroup
    if (is404) this.props.history.push('/404')

    // Load subscriber total (if residential customer)
    let subscriberTotal = null
    const isResidential = (activeGroup && (activeGroup.cust_type === 'RD' || activeGroup.cust_type === 'RP'))
    if (isResidential) {
      const customerId = await DataClient.lookupCustomerId(activeGroup.id)
      if (customerId) subscriberTotal = await DataClient.fetchSubscriberTotalWeight(customerId)
    }

    // Load relevant stats in the background (mount into activeGroup)
    const showWeeklyStats = (branding.show_weekly_stats !== false)
    if (activeGroup && activeGroup.id) {
      if (showWeeklyStats) {
        DataClient.fetchWeeklyStats(activeGroup.id).then(result => Object.assign(activeGroup, result))
      }
      DataClient.fetchMonthlyStats(activeGroup.id).then(result => Object.assign(activeGroup, result))
    }

    // Load org associations
    const orgAssocs = await DataClient.fetchOrgAssociations(organization.id)

    // Build active page URI (for shares)
    const activeUri = (activeGroup) ? `https://${orgAlias}.moonshotcompost.com/${activeGroup.url_alias}` : 'https://www.moonshotcompost.com/'

    // Load branding colors
    const brandingColors = DataClient.generateBrandingColors(branding)
    const modulePrimaryTextColor = brandingColors.modulePrimaryTextColor
    const moduleSecondaryTextColor = brandingColors.moduleSecondaryTextColor
    const pageLabelTextColor = brandingColors.pageLabelTextColor

    this.setState({
      activeUri,
      organization,
      orgAssocs,
      branding,
      groups,
      remoteGroups,
      activeGroup,
      groupTypeToShow,
      groupResultsTitle,
      bgImagePath,
      bgImageFromTop,
      modulePrimaryTextColor,
      moduleSecondaryTextColor,
      pageLabelTextColor,
      maxGroupsToShow,
      metricsToShow,
      totalResultsTitle,
      subscriberTotal,
      showQrCode,
      qrCodeUrl
    })
  }

  render () {
    const {
      activeUri, organization, orgAssocs, branding, groups, remoteGroups, activeGroup,
      groupTypeToShow, groupResultsTitle, bgImagePath, bgImageFromTop,
      modulePrimaryTextColor, moduleSecondaryTextColor, pageLabelTextColor,
      maxGroupsToShow, metricsToShow, totalResultsTitle, subscriberTotal, showQrCode, qrCodeUrl
    } = this.state

    // Determine state of dashboard page
    const isDataReady = Object.keys(organization).length > 0
    const isDashboardEnabled = isDataReady && activeGroup?.enable_dashboard === true

    const TooltipCss = withStyles({
      '@global': {
        '.MuiTooltip-popper': {
          marginTop: '8px',
          maxWidth: '180px'
        },
        '.MuiTooltip-tooltip': {
          margin: '0',
          padding: '16px 16px 18px 16px',
          backgroundColor: 'white',
          color: '#98B4CA',
          borderRadius: '0',
          boxShadow: '0px 2px 24px rgba(31, 36, 76, 0.25)',
          fontFamily: 'Lato',
          fontSize: '16px'
        }
      }
    })(() => null)

    // Show page is loading
    if (!isDataReady) {
      return <div style={{ fontFamily: 'Open Sans !important', fontSize: '1.1rem', textAlign: 'center', marginTop: '80px', marginBottom: '80px' }}>Loading Dashboard...</div>
    }

    // Show dashboard disabled message
    if (!isDashboardEnabled) {
      return <div style={{ fontFamily: 'Open Sans !important', fontSize: '1.1rem', textAlign: 'center', marginTop: '80px', marginBottom: '80px' }}>Dashboard not enabled.</div>
    }

    // Otherwise, render dashboard
    return (
      <>
        <Grid container className='group-page' justify='center' style={{ backgroundImage: `url(${bgImagePath})`, backgroundPosition: `center ${bgImageFromTop}px` }} spacing={0}>
          <Helmet>
            <title>{`${organization.name} Compost Results`}</title>
            <meta name='description' content='Food waste is a valuable resource that we’re transforming into healthy soil.' />
          </Helmet>
          <TooltipCss />
          <Grid item xs={12}>
            <AppHeader
              organization={organization}
              branding={branding}
              activeGroup={activeGroup}
            />
          </Grid>
          <Grid item xs={12}>
            <GroupPageTitle
              activeGroup={activeGroup}
              pageLabelTextColor={pageLabelTextColor}
            />
          </Grid>
          <Grid item xs={12}>
            <GroupPageContent
              activeUri={activeUri}
              activeGroup={activeGroup}
              organization={organization}
              orgAssocs={orgAssocs}
              groups={groups}
              remoteGroups={remoteGroups}
              branding={branding}
              modulePrimaryTextColor={modulePrimaryTextColor}
              moduleSecondaryTextColor={moduleSecondaryTextColor}
              pageLabelTextColor={pageLabelTextColor}
              metricsToShow={metricsToShow}
              subscriberTotal={subscriberTotal}
              groupTypeToShow={groupTypeToShow}
              groupResultsTitle={groupResultsTitle}
              maxGroupsToShow={maxGroupsToShow}
              totalResultsTitle={totalResultsTitle}
            />
          </Grid>
        </Grid>
        {showQrCode && <QrCode qrCodeUrl={qrCodeUrl} />}
      </>
    )
  }
}

export default withRouter(GroupPage)
