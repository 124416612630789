/* eslint-disable react/jsx-handler-names */
import React, { Component } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from 'react-share'
import './index.scss'

class ShareControls extends Component {
  constructor (options) {
    super()
    const activeUri = options.activeUri || 'http://moonshotcompost.com'

    this.state = {
      showMenu: false,
      url: activeUri,
      text: 'Compost with Moonshot',
      title: 'Compost with Moonshot',
      source: 'Moonshot'
    }

    this.showMenu = this.showMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  showMenu (event) {
    event.preventDefault()
    this.setState({ showMenu: true })
  }

  closeMenu () {
    this.setState({ showMenu: false })
  }

  render () {
    return (

      <div className='dd-wrapper'>
        <button
          className='share-btn'
          onClick={this.state.showMenu ? this.closeMenu : this.showMenu}
          style={this.state.showMenu ? { borderRadius: '8px 8px 0px 0px' } : { borderRadius: '8px' }}
        >
          <i />
        </button>
        {this.state.showMenu &&
          <div className='dd-list share-dd'>
            <FacebookShareButton
              url={this.state.url}
              quote={this.state.text}
              hashtag={this.state.source}
            >
              <FacebookIcon
                size={40}
                round={false}
                borderRadius={8}
                iconFillColor='#0A509E'
                bgStyle={{ fill: 'white' }}
              />
            </FacebookShareButton>
            <TwitterShareButton
              url={this.state.url}
              title={this.state.title}
              hashtags={[this.state.source]}
            >
              <TwitterIcon
                size={40}
                round={false}
                borderRadius={8}
                iconFillColor='#0A509E'
                bgStyle={{ fill: 'white' }}
              />
            </TwitterShareButton>
            <LinkedinShareButton
              url={this.state.url}
              title={this.state.title}
              summary={this.state.text}
              source={this.state.source}
            >
              <LinkedinIcon
                size={40}
                round={false}
                borderRadius={8}
                iconFillColor='#0A509E'
                bgStyle={{ fill: 'white' }}
              />
            </LinkedinShareButton>
            {/* </div> */}
          </div>}
      </div>
    )
  }
}

export default ShareControls
