import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Modal from '@material-ui/core/Modal'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import InfoIcon from '@material-ui/icons/Info'

const MetricIcon = (props) => {
  const value = props.value
  const title = props.title
  const text = props.text
  const modulePrimaryTextColor = props.modulePrimaryTextColor
  const moduleSecondaryTextColor = props.moduleSecondaryTextColor
  const [showModal, setShowModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [hoverState, setHoverState] = useState(false)

  const handleOpen = () => {
    setShowModal(true)
    setOpen(true)
  }

  const handleClose = () => {
    setShowModal(false)
    setOpen(false)
  }

  return (
    <div className={'group-summary-button ' + (props.styleName || '')}>
      {/** *************** metric image ****************/}
      <div className='metric-img'>
        <img src={props.src} />
      </div>
      {/** *************** metric value and info icon ****************/}
      <div className='flex'>
        <div className='value' style={{ color: modulePrimaryTextColor }}>
          {value}
          <sup onClick={() => handleOpen()} onMouseEnter={() => setHoverState(true)} onMouseLeave={() => setHoverState(false)}>
            {hoverState ? <InfoIcon fontSize='small' className='info-icon' /> : <InfoOutlinedIcon fontSize='small' className='info-icon' />}
          </sup>
        </div>
        <div className='title' style={{ color: moduleSecondaryTextColor }}>{title === 'Co2 Saved' ? <>Co<sub>2</sub> Saved</> : title}</div>
      </div>
      {/** *************** info modal ****************/}
      {showModal && (
        <Modal onClose={() => handleClose()} open={open}>
          <div className='modal-container modal-page-content-container'>
            <span className='modal-close' onClick={() => handleClose()}>
              <CloseIcon />
            </span>
            <img src={props.src} className='modal-img' />
            <div className='modal-title' style={{ color: modulePrimaryTextColor }}>{title === 'Co2 Saved' ? <>Co<sub>2</sub> Saved</> : title}</div>
            <hr style={{ width: '100%' }} />
            <div className='modal-text' style={{ color: moduleSecondaryTextColor }}>{text}</div>
          </div>
        </Modal>
      )}
    </div>
  )
}
export default MetricIcon
