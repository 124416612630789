import objectUtils from '../utils/object-utils'

export default class CustomOrgAssociation {
  constructor (options = {}) {
    this.model = 'CustomOrgAssociation'
    this.id = objectUtils.get(options, 'id', null)
    this.custom_org_id = objectUtils.get(options, 'custom_org_id', null)

    this.label = objectUtils.get(options, 'label', null)
    this.status = objectUtils.get(options, 'status', null)
    this.notes = objectUtils.get(options, 'notes', null)
    this.settings = objectUtils.get(options, 'settings', null)

    this.created_at = objectUtils.get(options, 'created_at', null)
    this.updated_at = objectUtils.get(options, 'updated_at', null)

    if (options.group) this.group = options.group
    if (options.organization) this.organization = options.organization
  }
}
