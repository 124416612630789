// import Tooltip from '@material-ui/core/Tooltip'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import './index.scss'
import flow from 'lodash/fp/flow'
import flatten from 'lodash/fp/flatten'
import groupBy from 'lodash/fp/groupBy'
import map from 'lodash/fp/map'
import mapValues from 'lodash/fp/mapValues'
import sumBy from 'lodash/fp/sumBy'
import toPairs from 'lodash/fp/toPairs'

export default function OrganizationAssociations (props) {
  // const organization = props.organization || {}
  const orgAssocsData = props.orgAssocs
  const branding = props.branding || {}

  // Module font color settings
  const modulePrimaryTextColor = props.modulePrimaryTextColor
  const moduleSecondaryTextColor = props.moduleSecondaryTextColor

  // Org name
  // const orgNameForDisplay = organization.nickname || organization.name

  // Module title
  const moduleTitle = branding.org_associations_title || 'Associations'
  const items = orgAssocsData.items
  const getLabel = (items) => items.length === 1 ? items[0].association_info.label_singular : items[0].association_info.label_plural
  const groups = flow(
    groupBy('association_type'),
    mapValues((items) => [items, getLabel(items)]),
    toPairs,
    map(flatten)
  )(items)

  return !!items && (
    <div className='org-associations'>
      <p className='module-title' style={{ color: modulePrimaryTextColor }}>{moduleTitle}</p>
      <p className='subtext' style={{ color: moduleSecondaryTextColor }}>(Not included in total weight)</p>
      <div className='container'>
        <div className='weight'>
          <p style={{ color: modulePrimaryTextColor }}>
            <i /> {Math.round(sumBy('total_weight')(items)).toLocaleString()} lbs.
          </p>
        </div>
        <ul>
          {
          groups.map(([type, groupItems, label]) =>
            <li key={type} className='count'>
              <PeopleOutlineIcon className='count-icon' />
              <span className='count-text'>
                {groupItems.length} {label}
              </span>
            </li>
          )
        }
        </ul>
      </div>
    </div>
  )
}
