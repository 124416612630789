import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import WeightSubmissionForm from '../../components/group/WeightSubmissionForm'
import './index.scss'

class CustomerLookupPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {}
    }
  }

  async componentDidMount () {
    const search = this.props.location.search // could be '?foo=bar'
    const params = new URLSearchParams(search)
    const location = params.get('location') // bar
    this.setState({ location })
  }

  render () {
    const { location } = this.state
    return (
      <Grid container spacing={0} direction='column' alignItems='center'>
        <Grid item>
          <WeightSubmissionForm isShowing location={location || 'N/A'} />
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(CustomerLookupPage)
