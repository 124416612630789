import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import AppHeader from '../../components/app/AppHeader'
import CustomPageTitle from '../../components/custom/CustomPageTitle'
import CustomPageContent from '../../components/custom/CustomPageContent'
import DataClient from '../../services/dataClient'
import './index.scss'

class CustomPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeUri: '',
      branding: {},
      bgImagePath: '',
      bgImageFromTop: 0,
      modulePrimaryTextColor: '#0a509e',
      moduleSecondaryTextColor: '#98b4ca',
      pageLabelTextColor: '#FFFFFF',
      metricsToShow: [],
      orgAlias: '',
      customOrg: {},
      customOrgTotal: {},
      customOrgAssocs: []
    }
  }

  async componentDidMount () {
    const branding = this.props.data.branding
    const orgAlias = this.props.data.orgAlias
    const customOrg = this.props.data.customOrg
    const customOrgAssocs = this.props.data.customOrgAssocs

    // Build active page URI (for shares)
    const activeUri = `https://custom.moonshotcompost.com/${orgAlias}`

    // Generate background image settings
    const bgImageInfo = DataClient.generateBgImageInfo(orgAlias, branding)
    const bgImagePath = bgImageInfo.bgImagePath
    const bgImageFromTop = bgImageInfo.bgImageFromTop

    // Generate branding colors
    const brandingColors = DataClient.generateBrandingColors(branding)
    const modulePrimaryTextColor = brandingColors.modulePrimaryTextColor
    const moduleSecondaryTextColor = brandingColors.moduleSecondaryTextColor
    const pageLabelTextColor = brandingColors.pageLabelTextColor

    // Generate metrics
    const metricsToShow = DataClient.generateMetricsToShow(branding)

    // Load current totals for all associations, aggregate into a total stats object
    const customOrgTotal = {
      stats_total: { total_in_lbs: 0 }
    }
    for (const assocRef of customOrgAssocs) {
      const org = assocRef.organization || {}
      const filtersGroups = (assocRef.settings && assocRef.settings.filter_groups) ? assocRef.settings.filter_groups : null
      const filters = (filtersGroups) ? filtersGroups.map(entry => entry.alias) : null

      // Fetch current totals org
      const totals = (org.url_alias) ? await DataClient.fetchCurrentTotals(org.url_alias, filters) : {}

      // Generate total, considering group filters
      const totalWeight = (filters && totals.locations)
        ? Object.values(totals.locations).reduce((sum, loc) => sum + Number(loc.totals.total_weight), 0)
        : totals.total_weight || 0

      // Aggregate custom org total
      customOrgTotal.stats_total.total_in_lbs += totalWeight
    }

    this.setState({
      activeUri,
      branding,
      bgImagePath,
      bgImageFromTop,
      modulePrimaryTextColor,
      moduleSecondaryTextColor,
      pageLabelTextColor,
      metricsToShow,
      orgAlias,
      customOrg,
      customOrgTotal,
      customOrgAssocs
    })
  }

  render () {
    const {
      activeUri, branding, bgImagePath, bgImageFromTop,
      modulePrimaryTextColor, moduleSecondaryTextColor, pageLabelTextColor, metricsToShow,
      orgAlias, customOrg, customOrgTotal, customOrgAssocs
    } = this.state

    const TooltipCss = withStyles({
      '@global': {
        '.MuiTooltip-popper': {
          marginTop: '8px',
          maxWidth: '180px'
        },
        '.MuiTooltip-tooltip': {
          margin: '0',
          padding: '16px 16px 18px 16px',
          backgroundColor: 'white',
          color: '#98B4CA',
          borderRadius: '0',
          boxShadow: '0px 2px 24px rgba(31, 36, 76, 0.25)',
          fontFamily: 'Lato',
          fontSize: '16px'
        }
      }
    })(() => null)

    return (orgAlias)
      ? (
        <Grid container className='custom-page' justify='center' style={{ backgroundImage: `url(${bgImagePath})`, backgroundPosition: `center ${bgImageFromTop}px` }} spacing={0}>
          <Helmet>
            <title>{`${customOrg.name} Compost Results`}</title>
            <meta name='description' content='Food waste is a valuable resource that we’re transforming into healthy soil.' />
          </Helmet>
          <TooltipCss />
          <Grid item xs={12}>
            <AppHeader
              branding={branding}
              organization={customOrg}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomPageTitle
              branding={branding}
              pageLabelTextColor={pageLabelTextColor}
              orgAlias={orgAlias}
              customOrg={customOrg}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomPageContent
              activeUri={activeUri}
              branding={branding}
              modulePrimaryTextColor={modulePrimaryTextColor}
              moduleSecondaryTextColor={moduleSecondaryTextColor}
              pageLabelTextColor={pageLabelTextColor}
              metricsToShow={metricsToShow}
              orgAlias={orgAlias}
              customOrg={customOrg}
              customOrgTotal={customOrgTotal}
              customOrgAssocs={customOrgAssocs}
            />
          </Grid>
        </Grid>
        )
      : (
        <div style={{ fontSize: '1.4em', color: '#525252', margin: '0 auto', textAlign: 'center', marginTop: '2rem' }}>
          Loading data...
        </div>
        )
  }
}

export default withRouter(CustomPage)
