import React from 'react'
import ReactDOM from 'react-dom'
import AppRouter from './router'
import serviceWorker from './serviceWorker'
import appUtils from './utils/app-utils'

startApp()

async function startApp () {
  // Determine URL context
  const hostname = (window) ? window.location.hostname : 'localhost'
  const subdomain = hostname.split('.')[0]
  const uri = (window) ? window.location.pathname : ''

  // Determine if dev or prod request
  const isDev = appUtils.isDevMode(subdomain)

  // Prepare data
  let data = {}
  if (appUtils.isDashboardPage(uri)) {
    const parsed = appUtils.parseUri(subdomain, isDev, uri)
    const parentAlias = parsed.parent
    const childAlias = parsed.child

    // Custom page
    if (parentAlias === 'custom') {
      if (childAlias) {
        data = await appUtils.loadCustomPageData(childAlias)
        data.pageType = (data.customOrg) ? 'custom' : '404'
      } else {
        data.pageType = '404'
      }

    // City page
    } else if (parentAlias === 'city-total') {
      data = await appUtils.loadCityPageData(childAlias)
      data.pageType = 'city'

    // Community page
    } else if (parentAlias === 'community-total') {
      data = await appUtils.loadCommunityPageData(childAlias)
      data.pageType = 'community'

    // Group page
    } else {
      data = await appUtils.loadGroupPageData(parentAlias)
      data.pageType = 'group'
    }
  }

  // Render app
  renderApp(data)
  serviceWorker()
}

function renderApp (data) {
  ReactDOM.render(
    <AppRouter data={data} />,
    document.getElementById('root')
  )
}
