import React from 'react'
import './index.scss'
import imgMoonshotLogo from '../../assets/moonshot-logo.svg'

export default function Page404 (props) {
  return (
    <div className='page-404'>
      <div><img src={imgMoonshotLogo} /></div>

      <h3>This page does not exist.</h3>
    </div>
  )
}
