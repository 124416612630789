import objectUtils from '../utils/object-utils'

export default class CustomOrganization {
  constructor (options = {}) {
    this.model = 'CustomOrganization'
    this.id = objectUtils.get(options, 'id', null)
    this.name = objectUtils.get(options, 'name', null)
    this.nickname = objectUtils.get(options, 'nickname', null)
    this.url_alias = objectUtils.get(options, 'url_alias', null)
    this.city_org_alias = objectUtils.get(options, 'city_org_alias', null)
    this.description = objectUtils.get(options, 'description', null)
    this.custom_org_type = objectUtils.get(options, 'custom_org_type', null)
    this.brandings = objectUtils.get(options, 'brandings', [])

    this.status = objectUtils.get(options, 'status', null)
    this.notes = objectUtils.get(options, 'notes', null)
    this.settings = objectUtils.get(options, 'settings', null)

    this.created_at = objectUtils.get(options, 'created_at', null)
    this.updated_at = objectUtils.get(options, 'updated_at', null)

    if (options.labels) this.labels = options.labels
  }
}
