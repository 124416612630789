import objectUtils from '../utils/object-utils'

export default class Group {
  constructor (options = {}) {
    this.model = 'Group'
    this.id = objectUtils.get(options, 'id', null)
    this.name = objectUtils.get(options, 'name', null)
    this.url_alias = objectUtils.get(options, 'url_alias', null)
    this.org_url_alias = objectUtils.get(options, 'org_url_alias', null)
    this.cust_id = objectUtils.get(options, 'cust_id', null)
    this.cust_status = objectUtils.get(options, 'cust_status', null)
    this.cust_type = objectUtils.get(options, 'cust_type', null)
    this.cust_category = objectUtils.get(options, 'cust_category', null)
    this.loc_id = objectUtils.get(options, 'loc_id', null)
    this.group_type = objectUtils.get(options, 'group_type', null)
    this.description = objectUtils.get(options, 'description', null)
    this.image_url = objectUtils.get(options, 'image_url', null)
    this.total_bins = objectUtils.get(options, 'total_bins', null)
    this.group_order = objectUtils.get(options, 'group_order', null)
    this.settings = objectUtils.get(options, 'settings', {})

    // Account info
    this.account_rep_name = objectUtils.get(options, 'account_rep_name', null)
    this.enable_dashboard = options.enable_dashboard

    // Auditing
    this.created_at = objectUtils.get(options, 'created_at', null)
    this.updated_at = objectUtils.get(options, 'updated_at', null)

    // Pickup location info
    if (options.pickup_location) this.pickup_location = options.pickup_location

    // Transform fields to match monthly and weekly stats shapes (for consistency)
    if (options.total_stats) {
      const totalInLbs = (options.total_stats.running_total_in_lbs) ? Number(options.total_stats.running_total_in_lbs) : 0
      this.stats_total = {
        total_in_lbs: totalInLbs,
        last_collection_date: options.total_stats.last_collection_date
      }
    }
  }
}
