// -----------------------------------------------------------------------------
// Normalizer for Moonshot API
// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------
// TO USE THIS SERVICE
// -----------------------------------------------------------------------------
// import toModel from '../services/normalizer'
//
// const payload = await http.get(uri, params)
// const model = toModel(payload)
//
// -----------------------------------------------------------------------------
// FOR MANY ITEMS REQUESTS
// -----------------------------------------------------------------------------
// If the query is a many items request, the result will be a Collection model.
// The Collection.items array will contain the specific model types (e.g. Group)
//
// Collection.items = [<Group>, <Group>]
//
// -----------------------------------------------------------------------------
// FOR SINGLE ITEM REQUESTS
// -----------------------------------------------------------------------------
// If the query is a single item request, the result will be the specific type.
// (e.g. Group -or- Organization)
//
// -----------------------------------------------------------------------------
import JointNormalizer from 'joint-normalizer'
import models from '../models'

// Initialize normalizer (with model factory)
const normalizer = new JointNormalizer({
  payloadSpec: 'json-api',
  models,
  debugInit: false,
  debug: false,
  debugToModel: false,
  logNamespace: '[normalizer]'
})

const toModel = payload => normalizer.normalizePayload(payload, true)

export default toModel
