/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState, useRef } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import dataClient from '../../../../services/dataClient'
import moment from 'moment'
import { sortBy } from '../../../../utils/object-utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const labels = [
  { code: '01', name: 'Jan' },
  { code: '02', name: 'Feb' },
  { code: '03', name: 'Mar' },
  { code: '04', name: 'Apr' },
  { code: '05', name: 'May' },
  { code: '06', name: 'Jun' },
  { code: '07', name: 'Jul' },
  { code: '08', name: 'Aug' },
  { code: '09', name: 'Sep' },
  { code: '10', name: 'Oct' },
  { code: '11', name: 'Nov' },
  { code: '12', name: 'Dec' }
]

const colorsActive = [
  'rgba(4, 128, 237, 1.0)', // royal blue
  'rgba(225, 65, 51, 0.9)', // orange red
  'rgba(243, 182, 3, 1.0)', // gold
  'rgba(49, 162, 80, 1.0)', // green
  'rgba(120, 163, 244, 1.0)', // light blue
  'rgba(65, 183, 192, 1.0)', // teal
  'rgba(246, 106, 0, 1.0)', // orange
  'rgba(171, 16, 33, 0.8)', // maroon
  'rgba(122, 57, 195, 0.7)', // purple
  'rgba(55, 222, 165, 0.85)', // aqua green
  'rgba(242, 86, 167, 0.85)' // pink
]

export default function StackedBarChart (props) {
  // Custom org data
  const customOrgAssocs = props.customOrgAssocs || []

  const [loading, setLoading] = useState(true)
  const [isChartDataReady, setIsChartDataReady] = useState(false)
  const [historicalTotals, setHistoricalTotals] = useState(null)
  const [renderableData, setRenderableData] = useState({})

  // Define DOM refs
  const chartContainerRef = useRef()

  // Validates if there is sufficient data to render the chart
  const validateChartDataReady = (historicalData) => {
    let hasData = false

    // Ensure all datasets have data to render
    if (Array.isArray(historicalData)) {
      for (const dataset of historicalData) {
        if (dataset.data) {
          hasData = true
        } else {
          hasData = false
          break
        }
      }
    }

    return hasData
  }

  // Utility function to aggregate group data to one obj
  const sumObjectsByKey = (...objs) => {
    const data = objs.reduce((a, b) => {
      for (const k in b) {
        if (b.hasOwnProperty(k)) { a[k] = (a[k] || 0) + b[k] }
      }
      return a
    }, {})
    return data
  }

  // Load data on mount
  useEffect(() => {
    async function loadData () {
      setLoading(true)

      // Get unique labels
      const uniqueLabels = customOrgAssocs.map((customOrg) => customOrg.label.name).filter((value, index, self) => self.indexOf(value) === index)

      // Map each unique label with their urlAliases
      const labelOrgMap = uniqueLabels.map((label) => {
        const orgs = customOrgAssocs.filter((assoc) => assoc.label.name === label).map((ele) => {
          return { alias: ele.organization.url_alias, filter_groups: ele.settings?.filter_groups || null }
        })
        return { label, orgs: orgs }
      })

      // Fetch historical totals for the associations to show
      const historicalData = await Promise.all(labelOrgMap.map(async (entry, i) => {
        const data = await Promise.all(entry.orgs.map(async (org) => {
          const historical = await dataClient.fetchHistoricalTotals(org.alias)
          // If the org has filter groups - lets only total those
          if (org.filter_groups) {
            const groupsToSum = []
            for (const group of org.filter_groups) {
              if (historical.locations[group.alias]) {
                groupsToSum.push(historical.locations[group.alias].totals)
              }
            }
            return sumObjectsByKey(...groupsToSum)
          } else {
            // If the org does not have any filter groups - proceed as normal with totaling
            // Get the list of locations
            const locations = (historical.locations) ? Object.keys(historical.locations) : []

            if (locations.length > 1) {
              // Put all "totals objects" into an array
              const locationTotals = locations.map((location) => historical.locations[location].totals)
              // Aggregate their common keys into one object
              return sumObjectsByKey(...locationTotals)
            } else if (locations.length === 1) {
              return historical.locations[locations[0]].totals
            }
          }
        }))

        // Need to return different datasets based on how many org historical datasets we have
        if (data.length === 1) {
          return { ...entry, data: data[0] }
        } else if (data.length > 1) {
          return { ...entry, data: sumObjectsByKey(...data) }
        }
      }))

      // Set data for chart display (if ready)
      if (validateChartDataReady(historicalData)) {
        setHistoricalTotals(historicalData)
        setIsChartDataReady(true) // signify chart is able to display
      }
      setLoading(false)
    }
    loadData()
  }, [])

  // Once historical totals and current totals are loaded, create the chart data
  useEffect(() => {
    if (historicalTotals) {
      createChartData()
    }
  }, [historicalTotals])

  // Define chart options
  const options = {
    plugins: {
      title: {
        display: false
      },
      legend: {
        labels: {
          boxWidth: 13
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  }

  // Create chart data
  function createChartData () {
    const dataset = historicalTotals.map((historical, i) => {
      return { label: historical.label, data: Object.values(historical.data).reverse(), backgroundColor: colorsActive[i] }
    })

    const sortedData = sortBy(dataset, 'label')

    const currentMonth = moment().format('MM')
    const currentMonthIdx = labels.findIndex((e) => e.code === currentMonth)

    const chartLabels = [...labels.slice(currentMonthIdx, labels.length), ...labels.slice(0, currentMonthIdx)].map((month) => month.name)

    setRenderableData({ labels: chartLabels, datasets: sortedData })
  }

  // Draw chart
  return (
    <div ref={chartContainerRef} className='chart-container'>
      {loading && 'Loading...'}
      <div className='chart-wrapper'>
        {/* the chart */}
        {renderableData && renderableData.datasets &&
          <Bar options={options} data={renderableData} style={{ height: '320px' }} />}

        {/* placeholder message when data not ready */}
        {!loading && !isChartDataReady &&
          <div className='chart-data-not-ready'>
            (Historical data is being processed)
          </div>}
      </div>
    </div>
  )
}
