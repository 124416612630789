import React from 'react'
import objectUtils from '../../../utils/object-utils'
import MetricDisplay from '../../app/MetricDisplay'
import ShareControls from '../../app/ShareControls'
import CustomOrgChart from '../CustomOrgChart'
import './index.scss'
import './grid.scss'

export default function CustomPageContent (props) {
  const activeUri = props.activeUri
  const branding = props.branding || {}
  const customOrg = props.customOrg || {}
  const customOrgAssocs = props.customOrgAssocs || []
  const customOrgTotal = props.customOrgTotal || {}

  // Metrics
  const metricsToShow = props.metricsToShow

  // Branding colors
  const modulePrimaryTextColor = props.modulePrimaryTextColor
  const moduleSecondaryTextColor = props.moduleSecondaryTextColor
  // const pageLabelTextColor = props.pageLabelTextColor

  return (
    <div className='page-content-container'>
      <div className='grid-container'>
        {/* Floating Buttons ----------------------------------------------- */}
        <div className='btn-container'>
          {activeUri && (
            <ShareControls activeUri={activeUri} />
          )}
        </div>

        {/* Total Weight Metrics ------------------------------------------- */}
        <div className='card metrics weight'>
          <span className='generated-total-weight-nbr' style={{ color: modulePrimaryTextColor }}>
            {displayCustomOrgTotalWeight({ customOrgTotal })}&nbsp;
            <sup id='lbs' style={{ color: moduleSecondaryTextColor }}>lbs.</sup>
          </span>
          <div className='card-subtext' style={{ color: moduleSecondaryTextColor }}>
            Diverted from landfill
          </div>
        </div>
        {metricsToShow.map((metric, index) => {
          return (
            <div className={`card metrics metric-card-${index}`} key={`metric_card_${index}`}>
              <MetricDisplay
                metricToShow={metric}
                timeframe='total'
                activeGroup={customOrgTotal}
                modulePrimaryTextColor={modulePrimaryTextColor}
                moduleSecondaryTextColor={moduleSecondaryTextColor}
              />
            </div>
          )
        })}

        {/* Chart -------- */}
        <div className='card chart-card chart-span'>
          <CustomOrgChart
            branding={branding}
            modulePrimaryTextColor={modulePrimaryTextColor}
            moduleSecondaryTextColor={moduleSecondaryTextColor}
            customOrg={customOrg}
            customOrgAssocs={customOrgAssocs}
          />
        </div>
      </div>
    </div>
  )
}

function displayCustomOrgTotalWeight (options) {
  const customOrgTotal = options.customOrgTotal || {}
  const totalWeight = objectUtils.get(customOrgTotal, 'stats_total.total_in_lbs', 0)
  const totalWeightForDisplay = Math.round(totalWeight).toLocaleString()

  return (
    totalWeightForDisplay
  )
}
