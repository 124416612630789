import React from 'react'
import objectUtils from '../../../utils/object-utils'
import DataClient from '../../../services/dataClient'
import MetricDisplay from '../../app/MetricDisplay'
import ShareControls from '../../app/ShareControls'
import CommercialStats from '../CommercialStats'
import ResidentialStats from '../ResidentialStats'
import './index.scss'
import './grid.scss'

export default function CityPageContent (props) {
  const branding = props.branding || {}
  const activeUri = props.activeUri
  // const cityOrg = props.cityOrg || {}
  const cityStats = props.cityStats || {}
  const commercialStats = cityStats.commercial
  const residentialStats = cityStats.residential

  // Set metrics to show
  const metricsToShow = ['trees-planted', 'miles-undriven', 'co2-saved']

  // Load branding colors
  const brandingColors = DataClient.generateBrandingColors(branding)
  const modulePrimaryTextColor = brandingColors.modulePrimaryTextColor
  const moduleSecondaryTextColor = brandingColors.moduleSecondaryTextColor
  // const pageLabelTextColor = brandingColors.pageLabelTextColor

  return (
    <div className='page-content-container'>
      <div className='grid-container'>
        {/* Floating Buttons ----------------------------------------------- */}
        <div className='btn-container'>
          {activeUri && (
            <ShareControls activeUri={activeUri} />
          )}
        </div>

        {/* Total Weight Metrics ------------------------------------------- */}
        <div className='card metrics weight'>
          <span className='generated-total-weight-nbr' style={{ color: modulePrimaryTextColor }}>
            {displayCityTotalWeight({ cityStats })}&nbsp;
            <sup id='lbs' style={{ color: moduleSecondaryTextColor }}>lbs.</sup>
          </span>
          <div className='card-subtext' style={{ color: moduleSecondaryTextColor }}>
            Diverted from landfill
          </div>
        </div>
        {metricsToShow.map((metric, index) => {
          return (
            <div className={`card metrics metric-card-${index}`} key={`metric_card_${index}`}>
              <MetricDisplay
                metricToShow={metric}
                timeframe='total'
                activeGroup={cityStats}
                modulePrimaryTextColor={modulePrimaryTextColor}
                moduleSecondaryTextColor={moduleSecondaryTextColor}
              />
            </div>
          )
        })}

        {/* Commercial Stats ----------------------------------------------- */}
        <div className='card stats'>
          <CommercialStats stats={commercialStats} />
        </div>

        {/* Residential Stats ---------------------------------------------- */}
        <div className='card stats'>
          <ResidentialStats stats={residentialStats} />
        </div>
      </div>
    </div>
  )
}

function displayCityTotalWeight (options) {
  const cityStats = options.cityStats || {}
  const totalWeight = objectUtils.get(cityStats, 'stats_total.total_in_lbs', 0)

  return (
    Math.round(totalWeight).toLocaleString()
  )
}
