import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import {
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Typography,
  ButtonGroup
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import DataClient from '../../../services/dataClient'
import appUtils from '../../../utils/app-utils'
import MoonshotBackground from '../../../assets/moonshot-background.png'
import MoonshotLogo from '../../../assets/moonshot-logo-white.png'
import WeightSubmissionStatus from './WeightSubmissionStatus'
import './index.scss'

const debug = false

export default function WeightSubmissionForm (props) {
  const handleClose = props.handleClose
  const location = props.location
  const activeGroup = props.activeGroup || {}
  const organization = props.organization || {}

  const [dropoffFormData, setDropoffFormData] = useState({
    container_type: 'bin',
    org_url_alias: activeGroup.org_url_alias,
    url_alias: activeGroup.url_alias
  })
  const [submitting, setSubmitting] = useState(false)
  const [subscriberData, setSubscriberData] = useState({})
  const [step, setStep] = useState(null)
  const [dropoffLocations, setDropoffLocations] = useState([])
  const [locationName, setLocationName] = useState(null)
  const [weightMinThreshold, setWeightMinThreshold] = useState(2)

  // Hook to render weight submission alert message
  const [showWeightSubmissionAlert, setShowWeightSubmissionAlert] = useState(false)
  const [weightSubmissionMessage, setWeightSubmissionMessage] = useState('')
  const [weightSubmissionSeverity, setWeightSubmissionSeverity] = useState('success')
  const [weightSubmissionInfo, setWeightSubmissionInfo] = useState({})

  const [formErrors, setFormErrors] = useState(false)

  function closeModal () {
    handleClose()
    // window.location.reload()
  }

  const weightSubmissionHeaderStyle =
    {
      backgroundImage: `url(${MoonshotBackground})`,
      backgroundSize: 'cover',
      paddingTop: '9px',
      paddingLeft: '16px',
      paddingRight: '16px'
    }

  // Fetch and set dropoff locations
  useEffect(async () => {
    const locationsData = await DataClient.fetchData({ resource: 'dropoff-locations' })
    setDropoffLocations(locationsData.items || [])
  }, [])

  // Fetch container type def to obtain min weight threshold
  useEffect(async () => {
    const containerType = await DataClient.fetchContainerTypeDef('bin')
    if (containerType?.details?.min_weight_in_lbs) setWeightMinThreshold(containerType.details.min_weight_in_lbs)
  }, [])

  // If a pre-loaded location is found, we should skip to the customer lookup step.
  useEffect(() => {
    if (location || !activeGroup.id) {
      if (debug) {
        console.log('Location found:', location)
      }
      const foundLocation = dropoffLocations.find(loc => loc.code === location)
      if (foundLocation) {
        // console.log('found location:', foundLocation)
        setLocationName(foundLocation.name)
        setDropoffFormData({
          ...dropoffFormData,
          dropoff_location: foundLocation.code
        })
      } else {
        setDropoffFormData({
          ...dropoffFormData,
          dropoff_location: null
        })
      }
      setStep('lookup')
    } else if (!location && activeGroup.id) {
      setStep('additional-fields')
    }
  }, [location, dropoffLocations])

  // Handle change of form data
  function handleChange (event) {
    setDropoffFormData({
      ...dropoffFormData,
      [event.target.name]: event.target.value
    })
  }

  // Handle submission of form data
  async function handleSubmit () {
    try {
      dropoffFormData.weight_in_lbs = Number(dropoffFormData.weight_in_lbs)
      dropoffFormData.group_id = activeGroup.id || subscriberData.group_id
      if (debug) console.log('Form data:', dropoffFormData)
      setSubmitting(true)
      const response = await DataClient.submitSubscriberWeight(dropoffFormData)
      if (debug) console.log('Response:', response)
      setWeightSubmissionInfo(response)
      setStep('submit-success')
    } catch (error) {
      setWeightSubmissionSeverity('error')
      setWeightSubmissionMessage('There was an error submitting your weight. Please try again in a moment.')
      setShowWeightSubmissionAlert(true)
    } finally {
      setSubmitting(false)
    }
  }

  // Handle lookup of subscriber data
  async function handleLookup () {
    if (dropoffFormData.email && dropoffFormData.email.length > 0) {
      try {
        setSubmitting(true)
        const customerLookupInfo = await DataClient.lookupSubscriberInfo({
          email: dropoffFormData.email
        })
        setWeightSubmissionSeverity('success')
        setWeightSubmissionMessage('Customer found!')
        setSubscriberData(customerLookupInfo)
        setDropoffFormData({ ...dropoffFormData, org_url_alias: customerLookupInfo.org_url_alias, url_alias: customerLookupInfo.group_url_alias })
        setStep('additional-fields')
      } catch (error) {
        setSubmitting(false)
        setWeightSubmissionSeverity('error')
        setWeightSubmissionMessage('Dropoff customer not found.')
      } finally {
        setShowWeightSubmissionAlert(true)
        setSubmitting(false)
      }
    }

    // Lookup customer data by phone number
    if (dropoffFormData.phone && dropoffFormData.phone.length > 0) {
      try {
        setSubmitting(true)
        const phoneReq = dropoffFormData.phone.replace(/[- )(]/g, '')
        const phone = `+1${phoneReq}` // include US country code to match system
        const customerLookupInfo = await DataClient.lookupSubscriberInfo({
          phone
        })
        // console.log('customer data will be set to:', customerLookupInfo)
        setWeightSubmissionSeverity('success')
        setWeightSubmissionMessage('Customer found!')
        setDropoffFormData({ ...dropoffFormData, org_url_alias: customerLookupInfo.org_url_alias, url_alias: customerLookupInfo.group_url_alias })
        setSubscriberData(customerLookupInfo)
        setStep('additional-fields')
        // console.log(customerLookupInfo)
      } catch (error) {
        setSubmitting(false)
        setWeightSubmissionSeverity('error')
        setWeightSubmissionMessage('Customer not found.')
      } finally {
        setShowWeightSubmissionAlert(true)
        setSubmitting(false)
      }
    }
  }

  // handle update of location data in state
  function handleLocationSelection (event) {
    setDropoffFormData({ ...dropoffFormData, dropoff_location: event.target.value })
  }

  // Formatting phone number
  useEffect(() => {
    if (dropoffFormData.phone && dropoffFormData.phone.length === 10) {
      const cleanedPhone = ('' + dropoffFormData.phone).replace(/\D/g, '')
      const formattedPhone = cleanedPhone.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '($1) $2-$3'
      )
      setDropoffFormData({
        ...dropoffFormData,
        phone: formattedPhone
      })
    }
  }, [dropoffFormData.phone])

  // Sets formErrors state based on form responses. Users cannot submit a form if they enter both an email and phone
  useEffect(() => {
    if (
      (step === 'lookup') &&
      dropoffFormData.email &&
      dropoffFormData.phone &&
      dropoffFormData.email.length > 0 &&
      dropoffFormData.phone.length > 0
    ) {
      return setFormErrors(true)
    } else setFormErrors(false)
  }, [dropoffFormData])

  // Validate form data on submission step
  useEffect(() => {
    if (
      (step === 'submit-data') &&
      (
        !dropoffFormData.weight_in_lbs ||
        (Number(dropoffFormData.weight_in_lbs) <= weightMinThreshold && dropoffFormData.container_type === 'bin') ||
        (Number(dropoffFormData.weight_in_lbs) <= 0 && dropoffFormData.container_type === 'none') ||
        !dropoffFormData.dropoff_location
      )
    ) {
      setFormErrors(true)
    }
  }, [dropoffFormData])

  return (
    <Grid container className='dropoff-form-container'>
      <Grid item xs={12} lg={location ? 12 : 6} className='weight-submission-header' style={location ? weightSubmissionHeaderStyle : null}>
        {location &&
          <Grid item xs={12} style={{ alignContent: 'flex-start', textAlign: 'left', paddingBottom: '5rem' }}>
            <img src={MoonshotLogo} style={{}} />
          </Grid>}
        <Grid item xs={12}>
          <WeightSubmissionStatus
            showWeightSubmissionAlert={showWeightSubmissionAlert}
            setShowWeightSubmissionAlert={setShowWeightSubmissionAlert}
            dropoffAlertSeverity='success'
            weightSubmissionMessage={weightSubmissionMessage}
            weightSubmissionSeverity={weightSubmissionSeverity}
          />
        </Grid>
        <Grid item xs={12} style={!location ? { height: '100%', borderRadius: '8px' } : { borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} className='weight-submission-col ws-head'>
          <div className='weight-submission-page-title'>
            <div className='title-box'>
              <div className='ws-sub-title'>Weight Submission</div>
              <div className='ws-sub-title-2'>
                {step === 'lookup' &&
                  <>
                    Enter your <b className='text-underline'>email address</b> or <b className='text-underline'>phone number</b> associated with your Moonshot account.
                  </>}
              </div>
              {activeGroup.name && organization.name && (
                <>
                  <div className='ws-name'>
                    Name: <strong>{activeGroup.name}</strong>
                  </div>
                  <div className='ws-name'>
                    Community:{' '}
                    <strong>
                      {organization.name}
                    </strong>
                  </div>
                </>
              )}
              {subscriberData.name && (
                <>
                  <div className='ws-name'>
                    Name: <strong>{subscriberData.name}</strong>
                  </div>
                  <div className='ws-name'>
                    Community:{' '}
                    <strong>
                      {subscriberData.org_name}
                    </strong>
                  </div>
                </>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={location ? 12 : 6} className='weight-submission-col'>
        <div className='dropoff-form'>
          {locationName && (step === 'lookup' || step === 'submit-data' || step === 'additional-fields') && (
            <div className='location-frontload'>
              Dropoff location:{' '}
              <br />
              <b className='location-frontload-name'>
                {locationName}
              </b>
            </div>
          )}
          {step === 'lookup' && (
            <div style={{ marginBottom: '2rem' }}>
              <TextField
                label='Email Address'
                className='input-text'
                name='email'
                value={dropoffFormData.email || ''}
                onChange={handleChange}
                aria-describedby='Email Address'
              />
              <p className='divider-text'>OR</p>
              <TextField
                label='Phone Number'
                className='input-text'
                name='phone'
                value={dropoffFormData.phone || ''}
                onChange={handleChange}
                aria-describedby='Phone Number'
              />
            </div>
          )}

          {step === 'additional-fields' && (
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <Typography align='left' className='weight-submission-text' style={{ fontSize: '20', color: 'rgba(0, 0, 0, 0.54', marginBottom: '1rem', marginTop: '2rem' }}>
                Does the weight include a Moonshot bin?
              </Typography>
              <ButtonGroup style={{ marginBottom: '1rem', width: '100%' }} disableElevation variant='contained'>
                <Button className='bin-toggle-button' variant={dropoffFormData.container_type === 'bin' ? '' : 'text'} onClick={() => setDropoffFormData({ ...dropoffFormData, container_type: 'bin' })}>Bin</Button>
                <Button className='bin-toggle-button' variant={dropoffFormData.container_type === 'none' ? '' : 'text'} onClick={() => setDropoffFormData({ ...dropoffFormData, container_type: 'none' })}>No Bin</Button>
              </ButtonGroup>
            </Grid>
          )}
          {step === 'submit-data' && (
            <>
              {!locationName && (
                <div className='location-selector-container'>
                  <FormControl className='location-selector'>
                    <InputLabel id='demo-simple-select-label'>
                      Dropoff location
                    </InputLabel>
                    <Select
                      value={dropoffFormData.dropoff_location || ''}
                      onChange={handleLocationSelection}
                    >
                      {dropoffLocations.map((location) => (
                        <MenuItem key={location.code} value={location.code}>
                          {location.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              <TextField
                className='input-text'
                type='date'
                name='collection_date'
                label='Dropoff date'
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={dropoffFormData.collection_date || ''}
              />
              <TextField
                label='Weight (lbs)'
                type='number'
                className='input-text'
                name='weight_in_lbs'
                value={dropoffFormData.weight_in_lbs || ''}
                onChange={handleChange}
                aria-describedby='Weight in Pounds'
              />
              <TextField
                style={{ overflowY: 'scroll', maxHeight: '150px' }}
                className='input-text'
                label='Comments (optional)'
                aria-describedby='Comments'
                multiline
                value={dropoffFormData.comments || ''}
                onChange={(e) => setDropoffFormData({ ...dropoffFormData, comments: e.target.value })}
              />
            </>
          )}
          {step === 'submit-success' && (
            <>

              <div className='submit-success-weight'>
                <CheckCircleOutlineIcon style={{ color: '#8BC242', fontSize: '27px', verticalAlign: 'bottom' }} />{weightSubmissionInfo.weight_in_lbs} lbs.
              </div>
              <div className='submit-success-weight-message'>
                Weight totals will be updated in your Diversion Dashboard within a week.
              </div>
              <div className='submit-success-weight-message'>
                Please send an email to
                <a className='submit-success-weight-link' href='mailto:support@moonshotcompost.com'> support@moonshotcompost.com </a> <br />
                for any issues and/or questions.
              </div>
            </>
          )}

          {/* Button is disabled until we have an endpoint to submit data to */}
          {step === 'submit-data' && (
            <Button
              className='dropoff-submit-button'
              color='primary'
              variant='contained'
              disabled={submitting || formErrors}
              onClick={() => handleSubmit()}
            >
              {submitting ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          )}
          {step === 'lookup' && (
            <Button
              className='dropoff-submit-button'
              color='primary'
              variant='contained'
              disabled={submitting || formErrors}
              onClick={() => handleLookup()}
            >
              {submitting ? <CircularProgress size={24} /> : 'Next'}
            </Button>
          )}
          {step === 'submit-success' && !location && (
            <a onClick={() => closeModal()} className='submit-success-weight-link'>Close</a>
          )}
          {step === 'additional-fields' && (
            <Button
              style={{ position: 'relative', bottom: '0' }}
              className='dropoff-submit-button'
              color='primary'
              variant='contained'
              disabled={submitting}
              onClick={() => setStep('submit-data')}
            >
              {submitting ? <CircularProgress size={24} /> : 'Next'}
            </Button>
          )}
          {step === 'submit-success' && location && subscriberData && (
            <a href={generateGroupPageUrl(subscriberData.org_url_alias, subscriberData.group_url_alias)} className='submit-success-weight-link'>Back to my dashboard</a>
          )}

          {formErrors && (
            <Typography color='error'>
              {step === 'lookup' && <>Please remove one of the fields.</>}
              {dropoffFormData.container_type === 'bin' && dropoffFormData.weight_in_lbs <= 2 && <>You must enter a weight greater than {weightMinThreshold} lbs.</>}
              {dropoffFormData.container_type === 'none' && dropoffFormData.weight_in_lbs <= 0 && <>You must enter a weight greater than 0 lbs.</>}
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

function generateGroupPageUrl (orgAlias, groupAlias) {
  const hostname = (window) ? window.location.hostname : 'localhost'
  const subdomain = hostname.split('.')[0]
  const isDev = appUtils.isDevMode(subdomain)
  const url = (isDev)
    ? `/${orgAlias}/${groupAlias}`
    : `https://${orgAlias}.moonshotcompost.com/${groupAlias}`
  return url
}
