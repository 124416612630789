import objectUtils from '../utils/object-utils'

export default class OrgAssociation {
  constructor (options = {}) {
    this.model = 'OrgAssociation'
    this.id = objectUtils.get(options, 'id', null)
    this.org_alias = objectUtils.get(options, 'org_alias', null)

    // Association
    this.association_type = objectUtils.get(options, 'association_type', null)
    this.association_info = options.association_info || {}
    this.associated_group_id = objectUtils.get(options, 'associated_group_id', null)
    this.associated_group_alias = objectUtils.get(options, 'associated_group_alias', null)
    this.associated_group_name = objectUtils.get(options, 'associated_group_name', null)
    this.total_weight = objectUtils.get(options, 'total_weight', null)
    this.status = objectUtils.get(options, 'status', null)
  }
}
