import React from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

export default function WeightSubmissionStatus (props) {
  const {
    weightSubmissionSeverity,
    weightSubmissionMessage,
    showWeightSubmissionAlert,
    setShowWeightSubmissionAlert
  } = props

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={showWeightSubmissionAlert}
      autoHideDuration={2000}
      onClose={() => setShowWeightSubmissionAlert(false)}
    >
      <Alert
        variant='filled'
        onClose={() => setShowWeightSubmissionAlert(false)}
        severity={weightSubmissionSeverity}
      >
        {weightSubmissionMessage}
      </Alert>
    </Snackbar>
  )
}
