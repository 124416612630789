import React, { useEffect, useState } from 'react'
import './index.scss'
import QRCode from 'qrcode'
import { createCanvas, loadImage } from 'canvas'
import MoonshotLogoCircle from '../../../assets/moonshot-logo-circle.png'

export default function QrCode ({ qrCodeUrl }) {
  const [qrCodeImageData, setQrCodeImageData] = useState(null)

  // Generate a QR code for the configured data
  async function generateQrCode () {
    try {
      // only adjust this number to change the size of the QR code. logo size will adjust automatically
      const qrCodeWidth = 200

      // lowering this will increase logo size, which may cause QR code to be unreadable
      const iconWidth = qrCodeWidth / 3.5

      const canvas = createCanvas(qrCodeWidth, qrCodeWidth)
      const ctx = canvas.getContext('2d')

      // create QR code
      await QRCode.toCanvas(canvas, qrCodeUrl, {
        errorCorrectionLevel: 'H',
        quality: 0.3,
        width: qrCodeWidth,
        margin: 1
      })

      // load image
      const image = await loadImage(MoonshotLogoCircle)

      // create white border around logo
      const iconPos = (qrCodeWidth - iconWidth) / 2
      ctx.drawImage(image, iconPos, iconPos, iconWidth, iconWidth)
      ctx.save()
      ctx.beginPath()
      ctx.arc(iconPos + iconWidth / 2, iconPos + iconWidth / 2, iconWidth / 2 + 5, 0, Math.PI * 2)
      ctx.fillStyle = 'white'
      ctx.fill()
      ctx.clip()
      ctx.closePath()
      // draw logo over top of the QR code
      ctx.drawImage(image, iconPos, iconPos, iconWidth, iconWidth)
      ctx.restore()

      setQrCodeImageData(canvas.toDataURL())
    } catch (err) {
      console.error('[DEVING] ERR =>', err)
    }
  }

  useEffect(() => {
    if (qrCodeUrl) {
      generateQrCode()
    }
  }, [])

  if (qrCodeImageData) {
    return (
      <div className='qr-code-center'>
        <div className='qr-code-container'>
          <img src={qrCodeImageData} className='qr-code' />
          <div className='qr-code-scan-me-container'>
            <div className='svg-container'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='M10 5h04' fill='none' stroke='black' strokeWidth='2' strokeLinecap='round' rx='5' ry='5' />
                <rect width='14' height='20' x='5' y='2' rx='2' ry='2' />
                <path d='M12 18h.01' />
              </svg>
            </div>
            <span className='scan-me'>SCAN ME</span>
          </div>
        </div>
      </div>
    )
  } else {
    return ('')
  }
}
