/* eslint-disable no-unneeded-ternary */
import React from 'react'
import DataClient from '../../../services/dataClient'
import PoweredByMoonshot from '../PoweredByMoonshot'
import './index.scss'

export default function AppHeader (props) {
  const organization = props.organization || {}
  const orgAlias = organization.url_alias
  const branding = props.branding || {}
  const activeGroup = props.activeGroup || {}

  // Generate org logo image to display
  const logoImagePath = DataClient.generateLogoImagePath(orgAlias, branding) // uses configured logo image (or falls back to system default)
  const logoImageHeight = branding.logo_image_height || 163
  const logoImageWidth = branding.logo_image_width || 400
  const showOrgLogo = (branding.logo_image_name) ? true : false // if no logo image configured, do not show

  // Determine if "powered by moonshot" should be displayed
  const showMoonshot = (branding.show_moonshot_in_header === true)

  // External link to Moonshot dropoff locations page
  const mainSiteDropoffLocations = 'https://www.moonshotcompost.com/dropoff_locations'

  return (
    <div className='app-header'>
      <LogoImage logoImagePath={logoImagePath} logoImageWidth={logoImageWidth} logoImageHeight={logoImageHeight} showOrgLogo={showOrgLogo} />
      {showMoonshot && <PoweredByMoonshot />}
      <LinkToDropoffs targetUrl={mainSiteDropoffLocations} activeGroup={activeGroup} showMoonshot={showMoonshot} />
    </div>
  )
}

function LogoImage ({ logoImagePath, logoImageWidth, logoImageHeight, showOrgLogo }) {
  if (showOrgLogo) {
    return (
      <div>
        <img src={logoImagePath} width={logoImageWidth + 'px'} height={logoImageHeight + 'px'} />
      </div>
    )
  } else {
    return ('')
  }
}

function LinkToDropoffs ({ targetUrl, activeGroup, showMoonshot }) {
  const showLinkToDropoffs = (process.env.REACT_APP_ENABLE_WEIGHT_SUBMISSION === 'yes' && activeGroup.cust_type === 'RD')
  const styleSettings = (showMoonshot) ? { margin: '1.18em auto 0 auto' } : {}

  if (showLinkToDropoffs) {
    return (
      <div className='link-to-dropoffs' style={styleSettings}>
        <a href={targetUrl} target='_new'>Find a Drop-off Location</a>
      </div>
    )
  } else {
    return ('')
  }
}
