import React from 'react'
import StackedBarChart from './StackedBarChart'
import './index.scss'

export default function CustomOrgChart (props) {
  const branding = props.branding || {}

  // Text colors
  const modulePrimaryTextColor = props.modulePrimaryTextColor
  const moduleSecondaryTextColor = props.moduleSecondaryTextColor

  // Custom org data
  // const customOrg = props.customOrg || {}
  const customOrgAssocs = props.customOrgAssocs || []

  // Chart title
  const chartTitle = branding.chart_title

  return (
    <div className='custom-org-chart'>
      {chartTitle && (
        <p className='chart-title' style={{ color: modulePrimaryTextColor }}>
          {chartTitle}
        </p>
      )}
      {(() => {
        if (customOrgAssocs.length > 0) {
          return (
            <StackedBarChart
              modulePrimaryTextColor={modulePrimaryTextColor}
              moduleSecondaryTextColor={moduleSecondaryTextColor}
              customOrgAssocs={customOrgAssocs}
            />
          )
        }
      })()}
    </div>
  )
}
