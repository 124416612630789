// -----------------------------------------------------------------------------
// Http Client for Moonshot API (Axios)
// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------
// TO USE THIS SERVICE
// (Instantiate an instance for your base URL)
// -----------------------------------------------------------------------------
//
// import HttpService from '../services/http'
//
// const http = new HttpService({
//   debug: true|false, // for debug logging
//   baseUrl: <the_api_base_url>
// })
//
// -----------------------------------------------------------------------------
// AVAILABLE FUNCTIONS
// -----------------------------------------------------------------------------
//
// http.get(uri, options)
// http.post(uri, options)
// http.put(uri, options)
// http.delete(uri, options)
//
// Where the options object supports:
//
// options.params: { paramA: valueA, paramB: value:B }
//
// -----------------------------------------------------------------------------
// EXAMPLE USAGE
// -----------------------------------------------------------------------------
//
// const uri = '/groups'
// const params = { with: 'monthlyStats' }
// const payload = await http.get(uri, params)
//
// -----------------------------------------------------------------------------
import axios from 'axios'

const defaultLogNamespace = '[HTTP]'

export default class HttpService {
  constructor (options) {
    this.debug = options.debug
    this.logNamespace = options.logNamespace || defaultLogNamespace
    this.baseUrl = options.baseUrl || ''
    this.timeout = options.timeout || 30 * 1000
    this.authToken = options.authToken

    const settings = {
      baseURL: this.baseUrl,
      timeout: this.timeout
    }
    if (this.authToken) settings.headers = { Authorization: this.authToken }

    // Instantiate HTTP client
    this.http = axios.create(settings)
  }

  get (uri, opts = {}) {
    opts.method = 'GET'
    return this.performRequest(uri, opts)
  }

  post (uri, opts = {}) {
    opts.method = 'POST'
    return this.performRequest(uri, opts)
  }

  put (uri, opts = {}) {
    opts.method = 'PUT'
    return this.performRequest(uri, opts)
  }

  delete (uri, opts = {}) {
    opts.method = 'DELETE'
    return this.performRequest(uri, opts)
  }

  async performRequest (uri, opts = {}) {
    const { method, bodyData, headers, validateStatus, ...params } = opts
    const data = bodyData
    const requestOpts = {
      params,
      headers: { ...headers },
      ...(validateStatus ? { validateStatus } : {})
    }

    if (this.debug) {
      console.log(`${this.logNamespace} Executing request ${method} ${uri}`)
      if (data) console.log(`${this.logNamespace} body =>`, data)
      console.log(`${this.logNamespace} provided options =>`, opts)
      console.log(`${this.logNamespace} parsed options =>`, requestOpts)
    }

    let request
    switch (method) {
      case 'GET': request = this.http.get(uri, requestOpts); break
      case 'POST': request = this.http.post(uri, data, requestOpts); break
      case 'PUT': request = this.http.put(uri, data, requestOpts); break
      case 'DELETE': request = this.http.delete(uri, requestOpts); break
      default: request = this.http.get(uri, requestOpts)
    }

    try {
      const payload = await request

      return payload.data
    } catch (error) {
      if (error.response && error.response.status) {
        console.error(`${this.logNamespace} Error =>`, error.response)
        throw (error.response.data)
      }
      if (error.request) {
        const noResponseError = { name: 'Network Error', status: 503, text: 'The server did not provide a response.' }
        console.error(`${this.logNamespace} Network Error =>`, noResponseError)
        throw (noResponseError)
      }
      console.error(`${this.logNamespace} Error =>`, error.message)
      throw error
    }
  }
}
