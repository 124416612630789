import objectUtils from '../utils/object-utils'

export default class Organization {
  constructor (options = {}) {
    this.model = 'Organization'
    this.id = objectUtils.get(options, 'id', null)
    this.name = objectUtils.get(options, 'name', null)
    this.nickname = objectUtils.get(options, 'nickname', null)
    this.url_alias = objectUtils.get(options, 'url_alias', null)
    this.parent_org_alias = objectUtils.get(options, 'parent_org_alias', null)
    this.description = objectUtils.get(options, 'description', null)
    this.logo_url = objectUtils.get(options, 'logo_url', null)
    this.org_type = objectUtils.get(options, 'org_type', null)
    this.brandings = objectUtils.get(options, 'brandings', [])

    this.created_at = objectUtils.get(options, 'created_at', null)
    this.updated_at = objectUtils.get(options, 'updated_at', null)

    if (options.groups) this.groups = options.groups
  }
}
