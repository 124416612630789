import objectUtils from '../utils/object-utils'

export default class DropoffLocation {
  constructor (options = {}) {
    this.model = 'DropoffLocation'
    this.id = objectUtils.get(options, 'id', null)
    this.code = objectUtils.get(options, 'code', null)
    this.name = objectUtils.get(options, 'name', null)
    this.url_alias = objectUtils.get(options, 'url_alias', null)
    this.community = objectUtils.get(options, 'community', null)
    this.coords = objectUtils.get(options, 'coords', null)
    this.comments = objectUtils.get(options, 'comments', null)
    this.notes = objectUtils.get(options, 'notes', null)

    this.email = objectUtils.get(options, 'email', null)
    this.email_secondary = objectUtils.get(options, 'email_secondary', null)
    this.phone = objectUtils.get(options, 'phone', null)
    this.phone_secondary = objectUtils.get(options, 'phone_secondary', null)
    this.street1 = objectUtils.get(options, 'street1', null)
    this.street2 = objectUtils.get(options, 'street2', null)
    this.city = objectUtils.get(options, 'city', null)
    this.state = objectUtils.get(options, 'state', null)
    this.zip = objectUtils.get(options, 'zip', null)

    this.created_at = objectUtils.get(options, 'created_at', null)
    this.updated_at = objectUtils.get(options, 'updated_at', null)
  }
}
