import Calculations from './calculations.js'
import MetricIcon from './MetricIcon'
import iconTreesPlanted from '../../../assets/icon-trees-planted-gray.svg'
import iconMilesUndriven from '../../../assets/icon-miles-undriven-gray.svg'
import iconCo2Saved from '../../../assets/icon-co2-saved-gray.svg'

const icons = {
  'trees-planted': iconTreesPlanted,
  'miles-undriven': iconMilesUndriven,
  'co2-saved': iconCo2Saved
}

export default function MetricDisplay (props) {
  const timeframe = props.timeframe
  const activeGroup = props.activeGroup
  const subscriberTotal = props.subscriberTotal // NOTE: Use this total for residential (will be null if commercial)
  const modulePrimaryTextColor = props.modulePrimaryTextColor
  const moduleSecondaryTextColor = props.moduleSecondaryTextColor
  const metricToShow = props.metricToShow

  // Resolve any dynamic requests
  const metricAlias = (metricToShow === 'weight-dynamic') ? generateDynamicMetricAlias({ activeGroup, timeframe }) : metricToShow

  // Build metric info (title, text)
  const metricInfo = generateMetricInfo({ metricAlias })

  // Generating Metric UI
  return (
    <MetricIcon
      src={icons[metricToShow]}
      value={generateMetricValue({ activeGroup, subscriberTotal, timeframe, metricAlias: metricToShow })}
      title={metricInfo.title}
      text={metricInfo.text}
      modulePrimaryTextColor={modulePrimaryTextColor}
      moduleSecondaryTextColor={moduleSecondaryTextColor}
    />
  )
}

function generateMetricInfo (options) {
  const metricAlias = options.metricAlias

  let title = ''
  switch (metricAlias) {
    case 'miles-undriven': title = 'Miles Undriven'; break
    case 'trees-planted': title = 'Planted Trees'; break
    case 'co2-saved': title = 'Pounds of CO₂ Saved'; break
    // ---------------------------------------------------- equivalence at 2 lbs
    case 'weight-in-pineapples': title = 'Pineapples'; break
    case 'weight-in-milk': title = 'Quarts of Milk'; break
    // ---------------------------------------------------- equivalence at 5 lbs
    case 'weight-in-roosters': title = 'Roosters'; break
    case 'weight-in-soda': title = '2-liter Sodas'; break
    // --------------------------------------------------- equivalence at 15 lbs
    case 'weight-in-bowling-balls': title = 'Bowling Balls'; break
    case 'weight-in-water': title = 'Gallons of Water'; break
    // --------------------------------------------------- equivalence at 50 lbs
    case 'weight-in-microwaves': title = 'Microwaves'; break
    case 'weight-in-bulldogs': title = 'Bulldogs'; break
    // -------------------------------------------------- equivalence at 100 lbs
    case 'weight-in-toilets': title = 'Toilets'; break
    case 'weight-in-bloodhounds': title = 'Bloodhounds'; break
  }

  let text = ''
  switch (metricAlias) {
    case 'trees-planted': text = 'This number of trees is generated by equating (1) how much carbon dioxide a typical urban tree sequesters in one year to (2) how much GHGs are avoided by composting food waste instead of landfilling it, per pound of waste. This calculation is based on modeling from the U.S. Environment Protection Agency.'; break
    case 'miles-undriven': text = 'This number of miles undriven is generated by equating (1) how much carbon dioxide is generated per mile driven to (2) how much GHGs are avoided by composting food waste instead of landfilling it, per pound of waste. This calculation is based on modeling from the U.S. Environmental Protection Agency.'; break
    case 'co2-saved': text = 'This amount of carbon dioxide is generated by converting the amount of GHGs avoided by composting food waste instead of landfilling it, per pound of waste, into pounds of carbon dioxide. This calculation is based on modeling from the U.S. Environmental Protection Agency.'; break
    case 'weight-in-pineapples': text = '2 lbs of compost weighs as much as one pineapple.'; break
    case 'weight-in-milk': text = '2 lbs of compost weighs as much as one quart of milk.'; break
    case 'weight-in-roosters': text = '5 lbs of compost weighs as much as one rooster.'; break
    case 'weight-in-soda': text = '5 lbs of compost weighs as much as a 2-liter bottle of soda.'; break
    case 'weight-in-bowling-balls': text = '15 lbs of compost weighs as much as one bowling ball.'; break
    case 'weight-in-water': text = '15 lbs of compost weighs as much as one gallon of water.'; break
    case 'weight-in-microwaves': text = '50 lbs of compost weighs as much as one microwave.'; break
    case 'weight-in-bulldogs': text = '50 lbs of compost weighs as much as one bulldog.'; break
    case 'weight-in-toilets': text = '100 lbs of compost weighs as much as one porcelain toilet.'; break
    case 'weight-in-bloodhounds': text = '100 lbs of compost weighs as much as one bloodhound.'; break
  }

  return { alias: metricAlias, title, text }
}

function generateDynamicMetricAlias (options) {
  const activeGroup = options.activeGroup || {}
  const timeframe = options.timeframe
  const randomKey = Math.floor(Math.random() * 2) // psuedo-randomly select a 0 or 1

  const totalWeightInLbs = getTotalWeightInLbs({ activeGroup, timeframe })

  let alias = (randomKey === 1) ? 'weight-in-pineapples' : 'weight-in-milk'
  if (totalWeightInLbs > 250) {
    alias = (randomKey === 1) ? 'weight-in-bloodhounds' : 'weight-in-toilets'
  } else if (totalWeightInLbs > 75) {
    alias = (randomKey === 1) ? 'weight-in-microwaves' : 'weight-in-bulldogs'
  } else if (totalWeightInLbs > 25) {
    alias = (randomKey === 1) ? 'weight-in-bowling-balls' : 'weight-in-water'
  } else if (totalWeightInLbs > 10) {
    alias = (randomKey === 1) ? 'weight-in-roosters' : 'weight-in-soda'
  }

  return alias
}

function generateMetricValue (options) {
  const activeGroup = options.activeGroup || {}
  const subscriberTotal = options.subscriberTotal
  const timeframe = options.timeframe
  const metricAlias = options.metricAlias

  const totalWeightForMetric = getTotalWeightInLbs({ activeGroup, subscriberTotal, timeframe })

  let metricValue = 0
  switch (metricAlias) {
    case 'trees-planted': metricValue = Calculations.calculateTreesPlanted(totalWeightForMetric / 2000); break
    case 'miles-undriven': metricValue = Calculations.calculateMilesUndriven(totalWeightForMetric / 2000); break
    case 'co2-saved': metricValue = Calculations.calculateCo2Saved(totalWeightForMetric / 2000); break
    case 'weight-in-pineapples': metricValue = totalWeightForMetric / 2; break
    case 'weight-in-milk': metricValue = totalWeightForMetric / 2; break
    case 'weight-in-roosters': metricValue = totalWeightForMetric / 5; break
    case 'weight-in-soda': metricValue = totalWeightForMetric / 5; break
    case 'weight-in-bowling-balls': metricValue = totalWeightForMetric / 15; break
    case 'weight-in-water': metricValue = totalWeightForMetric / 15; break
    case 'weight-in-microwaves': metricValue = totalWeightForMetric / 50; break
    case 'weight-in-bulldogs': metricValue = totalWeightForMetric / 50; break
    case 'weight-in-toilets': metricValue = totalWeightForMetric / 100; break
    case 'weight-in-bloodhounds': metricValue = totalWeightForMetric / 100; break
  }
  metricValue = (metricValue) ? Math.round(metricValue) : 0

  return metricValue.toLocaleString()
}

function getTotalWeightInLbs (options) {
  const activeGroup = options.activeGroup || {}
  const subscriberTotal = options.subscriberTotal
  const timeframe = options.timeframe

  // Obtain total weights
  const groupTotalWeight = (activeGroup.stats_total) ? activeGroup.stats_total.total_in_lbs : 0
  const groupMonthlyWeight = (activeGroup.stats_monthly) ? activeGroup.stats_monthly.total_in_lbs : 0
  const groupWeeklyWeight = (activeGroup.stats_weekly) ? activeGroup.stats_weekly.total_in_lbs : 0

  let totalWeightInLbs = 0
  switch (timeframe) {
    case 'total': totalWeightInLbs = subscriberTotal || groupTotalWeight; break
    case 'monthly': totalWeightInLbs = groupMonthlyWeight; break
    case 'weekly': totalWeightInLbs = groupWeeklyWeight; break
  }

  return totalWeightInLbs
}
