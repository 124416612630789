import objectUtils from '../utils/object-utils'

export default class WasteCollectionSubmission {
  constructor (options = {}) {
    this.model = 'WasteCollectionSubmission'
    this.id = objectUtils.get(options, 'id', null)
    this.group_id = objectUtils.get(options, 'group_id', null)
    this.submission_status = objectUtils.get(options, 'submission_status', null)
    this.customer_name = objectUtils.get(options, 'customer_name', null)
    this.weight_in_lbs = objectUtils.get(options, 'weight_in_lbs', null)
    this.org_url_alias = objectUtils.get(options, 'org_url_alias', null)
    this.group_url_alias = objectUtils.get(options, 'group_url_alias', null)
    this.container_type = objectUtils.get(options, 'container_type', null)
    this.collection_code = objectUtils.get(options, 'collection_code', null)
    this.dropoff_location_code = objectUtils.get(options, 'dropoff_location_code', null)

    this.collected_at = objectUtils.get(options, 'collected_at', null)
    this.submitted_at = objectUtils.get(options, 'submitted_at', null)
    this.created_at = objectUtils.get(options, 'created_at', null)
    this.updated_at = objectUtils.get(options, 'updated_at', null)
  }
}
