import React from 'react'
import './index.scss'

export default function GroupPageTitle (props) {
  const activeGroup = props.activeGroup || {}
  const pageLabelTextColor = props.pageLabelTextColor

  return (
    <div className='page-title'>
      <div className='title-box'>
        <div className='title' style={{ color: pageLabelTextColor }}>{activeGroup.name}</div>
        <hr />
        <div className='sub-title' style={{ color: pageLabelTextColor }}>Compost Results</div>
      </div>
    </div>
  )
}
