import React from 'react'
import './index.scss'
import imgMoonshotLogo from '../../../assets/moonshot-logo.svg'

export default function PoweredByMoonshot () {
  return (
    <div>
      <div className='powered-by-moonshot'>
        <p>Powered by </p> <a href='https://www.moonshotcompost.com/'><img src={imgMoonshotLogo} /></a>
      </div>
    </div>
  )
}
