import objectUtils from '../utils/object-utils'

export default class WasteCollection {
  constructor (options = {}) {
    this.model = 'WasteCollection'
    this.id = objectUtils.get(options, 'id', null)
    this.group_id = objectUtils.get(options, 'group_id', null)
    this.cust_id = objectUtils.get(options, 'cust_id', null)
    this.loc_id = objectUtils.get(options, 'loc_id', null)
    this.container_id = objectUtils.get(options, 'container_id', null)
    this.container_type = objectUtils.get(options, 'container_type', null)
    this.collection_code = objectUtils.get(options, 'collection_code', null)
    this.dropoff_location_code = objectUtils.get(options, 'dropoff_location_code', null)
    this.total_weight_in_lbs = objectUtils.get(options, 'total_weight_in_lbs', null)
    this.collected_at = objectUtils.get(options, 'collected_at', null)
    this.created_at = objectUtils.get(options, 'created_at', null)
    this.updated_at = objectUtils.get(options, 'updated_at', null)
  }
}
