import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import AppHeader from '../../components/app/AppHeader'
import CityPageTitle from '../../components/city/CityPageTitle'
import CityPageContent from '../../components/city/CityPageContent'
import DataClient from '../../services/dataClient'
import './index.scss'

class CityPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeUri: '',
      branding: {},
      cityAlias: '',
      cityOrg: {},
      cityStats: {}
    }
  }

  async componentDidMount () {
    const cityAlias = this.props.data.cityAlias
    const cityOrg = this.props.data.cityOrg
    const branding = this.props.data.branding

    // Redirect to 404 page, if group does not exist
    // const is404 = !activeGroup
    // if (is404) this.props.history.push('/404')

    // Load city level stats
    const cityStats = await DataClient.fetchCityLevelStats(cityAlias)
    // console.log('[DEVING] cityStats =>', cityStats)

    // Build active page URI (for shares)
    const activeUri = `https://city-total.moonshotcompost.com/${cityAlias}`

    this.setState({
      activeUri,
      branding,
      cityAlias,
      cityOrg,
      cityStats
    })
  }

  render () {
    const {
      activeUri, branding, cityAlias, cityOrg, cityStats
    } = this.state

    const TooltipCss = withStyles({
      '@global': {
        '.MuiTooltip-popper': {
          marginTop: '8px',
          maxWidth: '180px'
        },
        '.MuiTooltip-tooltip': {
          margin: '0',
          padding: '16px 16px 18px 16px',
          backgroundColor: 'white',
          color: '#98B4CA',
          borderRadius: '0',
          boxShadow: '0px 2px 24px rgba(31, 36, 76, 0.25)',
          fontFamily: 'Lato',
          fontSize: '16px'
        }
      }
    })(() => null)

    return (
      <Grid container className='city-page' justify='center' spacing={0}>
        <Helmet>
          <title>{`${cityOrg.name} Compost Results`}</title>
          <meta name='description' content='Food waste is a valuable resource that we’re transforming into healthy soil.' />
        </Helmet>
        <TooltipCss />
        <Grid item xs={12}>
          <AppHeader
            branding={branding}
            organization='montrose'
          />
        </Grid>
        <Grid item xs={12}>
          <CityPageTitle
            branding={branding}
            cityAlias={cityAlias}
            cityOrg={cityOrg}
          />
        </Grid>
        <Grid item xs={12}>
          <CityPageContent
            branding={branding}
            activeUri={activeUri}
            cityAlias={cityAlias}
            cityOrg={cityOrg}
            cityStats={cityStats}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(CityPage)
