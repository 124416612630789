const lodashObject = require('lodash/object')
const lodashLang = require('lodash/lang')
const lodashArray = require('lodash/array')
const lodashCollection = require('lodash/collection')

module.exports = {
  get: lodashObject.get,
  has: lodashObject.has,
  isEmpty: lodashLang.isEmpty,
  cloneDeep: lodashLang.cloneDeep,
  union: lodashArray.union,
  includes: lodashCollection.includes,
  orderBy: lodashCollection.orderBy,
  sortBy: lodashCollection.sortBy
}
