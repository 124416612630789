import DataClient from '../services/dataClient'

const nonDashboardPages = ['/submitweight', '/404']

// Determines if request is dev or production
const isDevMode = (subdomain) => {
  return (subdomain === 'localhost' || subdomain === '127' || subdomain.startsWith('moonshot-'))
}

// Determines if dashboard page request (i.e. city, community, or group page)
const isDashboardPage = (uri) => {
  for (let i = 0; i <= nonDashboardPages.length; i++) {
    if (nonDashboardPages[i] === uri) return false
  }
  return true
}

// Parse uri into context for app
const parseUri = (subdomain, isDev, uri) => {
  const uriParts = uri.split('/')
  const firstPath = uriParts[1]
  const secondPath = (uriParts.length > 2) ? uriParts[2] : null

  const parsed = {}
  parsed.parent = (isDev) ? firstPath : subdomain
  parsed.child = secondPath || firstPath
  return parsed
}

// Load data for Custom Page requests
const loadCustomPageData = async (orgAlias) => {
  let customOrg = null
  let branding = {}
  let customOrgAssocs = []

  try {
    // Load custom org element
    customOrg = await DataClient.loadCustomOrganization({ params: { orgAlias } })
    // console.log('[DEVING] Loaded custom org:', customOrg)

    // Obtain branding settings
    branding = (customOrg.brandings && customOrg.brandings.length > 0) ? customOrg.brandings[0].settings : {}

    // Load custom org associations
    const associations = await DataClient.fetchCustomOrgAssociations(customOrg.id)
    customOrgAssocs = (associations && associations.items) ? associations.items : []
    // console.log('[DEVING] Loaded custom org assocs:', customOrgAssocs)
  } catch (err) {}

  return { orgAlias, branding, customOrg, customOrgAssocs }
}

// Load data for City Page requests
const loadCityPageData = async (orgAlias) => {
  const cityAlias = orgAlias
  // Load residential city org element (for labels, etc)
  const cityOrg = await DataClient.loadOrganization({ params: { orgAlias } })

  return { cityAlias, cityOrg }
}

// Load data for Community Page requests
const loadCommunityPageData = async (orgAlias) => {
  const communityAlias = orgAlias
  // Load residential community org element
  const communityOrg = await DataClient.loadOrganization({ params: { orgAlias } })

  return { communityAlias, communityOrg }
}

// Load data for Group Page requests
const loadGroupPageData = async (orgAlias) => {
  // Load org and group data
  const organization = await DataClient.loadOrganization({ params: { orgAlias } })
  const groupData = await DataClient.loadGroups({ params: { orgAlias } })

  // Set groups collection for local (active) org
  const groups = groupData.items || []

  // Obtain org-level branding settings
  const branding = (organization.brandings && organization.brandings.length > 0) ? organization.brandings[0].settings : {}
  const showCompostGuide = branding.show_compost_guide
  const groupResultsOrgSource = branding.group_results_org_source

  // Fetch groups with stats from a remote org (for group results)
  const remoteGroups = (groupResultsOrgSource)
    ? await fetchGroupsWithStatsFromOrg(groupResultsOrgSource)
    : null

  // Determine default group
  const firstGroup = (groups[0]) ? groups[0].url_alias : null // default to first group, if "default_group" not set
  const defaultGroup = branding.default_group || firstGroup || '404'

  return { orgAlias, organization, branding, groups, defaultGroup, remoteGroups, showCompostGuide }
}

// This function is for fetching a groups with stats from a remote org
async function fetchGroupsWithStatsFromOrg (orgAlias) {
  const groupData = await DataClient.fetchData({ resource: 'groups', params: { orgAlias } })
  return groupData.items || []
}

export default {
  isDevMode,
  isDashboardPage,
  parseUri,
  loadCustomPageData,
  loadCityPageData,
  loadCommunityPageData,
  loadGroupPageData
}
