import React from 'react'
import objectUtils from '../../../utils/object-utils'
import './index.css'

export default function ResidentialStats (props) {
  const stats = props.stats || {}
  // console.log('[DEVING] ResidentialStats:', stats)

  const moduleTitle = 'Residential Totals'
  const modulePrimaryTextColor = '#000000'

  return (
    <div className='residential-stats'>
      <p className='module-title' style={{ color: modulePrimaryTextColor }}>
        {moduleTitle}
      </p>
      <div>
        total: {displayTotalWeight({ stats })}
      </div>
      <div>
        communities: {stats.community_count}
      </div>
      <div>
        subscribers: {stats.subscriber_count}
      </div>
    </div>
  )
}

function displayTotalWeight (options) {
  const stats = options.stats || {}
  const totalWeight = objectUtils.get(stats, 'total_weight', 0)

  return (
    totalWeight.toLocaleString()
  )
}
