import objectUtils from '../utils/object-utils'

export default class SubscriberTotalInfo {
  constructor (options = {}) {
    this.model = 'SubscriberTotalInfo'
    this.id = objectUtils.get(options, 'id', null)
    this.name = objectUtils.get(options, 'name', '')
    this.customer_id = objectUtils.get(options, 'customer_id', null)
    this.running_total_in_lbs = objectUtils.get(options, 'running_total_in_lbs', 0)
    this.groups = objectUtils.get(options, 'groups', [])
  }
}
