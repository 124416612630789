import React from 'react'
import './index.scss'

const ProgressCircle = ({ percentage }) => {
  // Overall size of progress circle
  const size = 100

  // Size of inner-darker progress circle (smaller the number, thinner the circle)
  const strokeWidth = 10

  // Constants - do not modify
  const radius = (size - strokeWidth) / 2
  const dashArray = radius * Math.PI * 2
  const dashOffset = dashArray - (dashArray * percentage) / 100

  // Style objects for SVG circle
  const circleProgress = {
    strokeDasharray: dashArray,
    strokeDashoffset: dashOffset
  }

  return (
    <div className='progress-circle-container'>
      <svg width={size} height={size}>
        <circle className='circle-bg' cx={size / 2} cy={size / 2} r={radius} strokeWidth={`${strokeWidth}px`} />
        <circle
          className='circle-progress'
          style={circleProgress}
          cx={size / 2} cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
        <text className='circle-text' x='50%' y='50%' dy='0.3em' textAnchor='middle'>
          {percentage}%
        </text>
      </svg>
    </div>
  )
}

export default ProgressCircle
